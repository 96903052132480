import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLevelUpAlt } from '@fortawesome/pro-solid-svg-icons'
import RECAPTCHA from 'react-google-recaptcha'

export default function ContactForm() {
    const [message, setMessage] = useState('')

    const { register, formState, reset, errors, watch } = useForm({
        mode: "onChange"
    });
    const [succeeded, setSucceeded] = useState(null);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const watchObject = watch();
    const [disableSubmit, setDisableSubmit] = useState(true);
    
    const handleChange = async (event) => {
        setError(event.error ? event.error.message : "");
    }

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
          
        //alert(JSON.stringify(watchObject));
        //setSucceeded(true);
   
    fetch('https://faz9wvv24c.execute-api.us-west-2.amazonaws.com/production/tvmh-contact-form', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
      },            
          body: JSON.stringify(watchObject)
  })
  .then((response) => {
      if (response.status === 200) {
          //setMessage('Message Sent!');
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          reset();
      } else {
          console.log('network error')
      }
  })
  setTimeout((data) => { 
}, 4000)
    }

    return (
        <Container id="contact" fluid 
            style={{
                backgroundColor: '#928D63',
                marginTop: '-2px'
            }}>
                <Row>
                    <Col>
                    <Container>
                        <p style={{color: 'white', fontSize: '1.25rem', textAlign: 'center', fontWeight: 300}}>Interested in our programs, volunteering, or want to make a large donation? Please contact Sara McKeown White, our Executive Director, for more information.</p>
                        <p style={{color: 'white', fontSize: '1.25rem', textAlign: 'center', fontWeight: 500}}>Teton Valley Mental Health Coalition<br/>76 N Main St, Ste 206<br/>Driggs, ID 83422<br /><a href="tel:208-354-6198" style={{color: 'white'}}>(208) 354-6198</a></p>
                        <p style={{color: 'white', fontSize: '1.25rem', textAlign: 'center', fontWeight: 300}}><span style={{fontStyle: 'italic', fontWeight: '300 !important'}}>We are not a service provider... </span>
Due to the high call volume of people in crisis, we have chosen to not have our phone number on the website so no one is confused to call us in an emergency. We hope that you understand and will reach out to us through our form below. Thank you!
</p>
            <h2 className="sectionTitle" style={{color: 'white'}}>Contact Sara</h2>
            
            <Form onSubmit={handleSubmit}
                id="contact-form"
                name="contact-form"
            >
                <Form.Group>
                    <Form.Label style={{color: 'white'}}>
                        Your Name
                    </Form.Label>
               <Form.Control
            className="formField"
            type="text"
            name="name"
            placeholder="(required)"
            ref={register({ required: true })}
          />
           {" "}{errors.name && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
       
        <Row>
            <Col>
            <Form.Group>
            <Form.Label style={{color: 'white'}}>
                Your Email Address
            </Form.Label>
          <Form.Control
          className="formField"
            type="email"
            name="email"
            placeholder="Email (required)"
            ref={register({ required: "Enter your e-mail",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid e-mail address",
            }, })}
          />
          {" "}{errors.email && <span className="formError">{errors.email.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        
        </Form.Group>
            </Col>
            
            <Col>
            <Form.Label style={{color: 'white'}}>
                Your Phone Number
            </Form.Label>
            <Form.Control
          className="formField"
            type="text"
            name="phone"
            placeholder="Phone"
            ref={register({ pattern: {
                  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  message: "Please enter a valid phone number"
                },
            })}
          />
          {" "}{errors.phone && <span className="formError">{errors.phone.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
            </Col>
        </Row>
        
        <Form.Group>
            <Form.Label style={{color: 'white'}}>
                Tell us about your needs or ask any questions
            </Form.Label>
                <Form.Control className="formField"
                placeholder="(required)"
                 as="textarea" name="message" ref={register({ required: true })} />
                 {" "}{errors.message && <span className="formError">Please enter a message <FontAwesomeIcon
                          icon={faLevelUpAlt}
                          style={{
                              fontSize: '14px',
                          }}
                          aria-hidden="true"
                      /></span> }
              </Form.Group> 

              <div className="my-2">
              <RECAPTCHA 
                sitekey="6Le5DDkaAAAAAJRSzvSqTygavXhdVpMKPl_rCh0I"
                size="normal"

                onChange={useCallback(() => setDisableSubmit(false))}
              />
            </div>

            {formState.isValid || succeeded ? (
              <Button type="submit" form="contact-form" disabled={disableSubmit} className="submitButton purpleButton">
                  <span id="button-text">
                                { succeeded ? (
                                  'Message sent!'
                                ) :
                                processing ? (
                                    'Submitting...'
                                ) : 'Submit' }
                            </span>
            </Button>
            ) : null }
            </Form>
            </Container>
                    </Col>
                </Row>

        </Container>
    )
}