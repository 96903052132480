import React from "react";
import styled from "styled-components"

const Floater = styled.svg `
  margin: 20px 0 20px 0;
  width: 85%;
  height: auto;
      .cls-1 {
        fill: #434081;
      }
`;

const FreeCounseling = (props) => {
    return (

<Floater xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550.20996 79.69002">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M4.04,35.06H3.99l-.09.15-.28-.05H3.3l-.47.19-.33-.1-.14-.09-.19.09-.38.1-.14.09-.37-.09L.86,35.3l-.19-.14-.19-.19v-.84L.34,33.7l-.05-1L.2,31.11.15,30.03V28.39L0,26.56v-.94l.09-.47V20.24l.05-1.22v-.94L.09,16.91l.1-.71V14.66l.05-1.08V10.67L.19,8.89V7.95L.24,6.54.29,5.08V2.6l.14-.33.33-.14.37.05.33-.1.23.1.71-.05.75.05H4.7l.47-.09.84-.05.7-.23.85-.05.89.05.56-.05.57.05.84-.05h.94l.28.05h.38l.32-.05.52.09h1.41l.42.09.38-.14.75.09h1.8l.56-.14.89.09h.66l.28.14.09.33.24.38v.42l.19.47.23.51-.14.28v.81l-.14.33v.37l.1.33-.33.38v.56l-.33.33-.28.23-.38.14h-.14l.14.24-.09.19-.28-.1-.24.19H17.32l-.24.09-.33-.09h-.32l-.19.18-.14.1h-.24l-.09-.14h-.85l-.3.05-.29.09h-.7l-.01.11h.09l-.05.1h-.23l-.42-.05H12.3l-.28.09h-.61l-.19-.05-.32.14-.8.05H8.93l-.94.1-.33.32v4.98H9.72l.33.09h1.69l.52-.05h1.08l.51.1h.47l1.32.09h.65l.75.09v.09l.05.29.14.18v.44l.09.28v.57l.14.23v.19l-.1.51v.52l-.1.42.1.24-.1.14-.18.14v.28l.24.14h.28l.09.19-.47.09-.56.33-.19.33h-.18l-.15.19-.14.14.14.1h.24l-.09.19-.43.19-.23.28-.28-.05h-.24l-.28.09h-.21l-.38-.09-.37.09-.52.14h-.33l-.4.13H10.04l-.7.09H7.65l-.19.05v.14l-.05.37v.61l-.09.85v3.89l.1.8-.1.24v1.4l-.14.47v.42l-.1-.28v-.42H7.03l-.1.09v.38l.09.37-.09.47v1.36l.19.1.14-.14h.1v.19l-.1.19.14.28-.47.19.05.19v.18l-.23.1-.19.19-.09-.1H6.32l-.14.14-.23.14H5.62l-.14.18-.14-.14-.28.14-.19.15-.37.09-.1.23H4.35ZM6.9,29.2v-.14H6.76l-.05.09.05.09.09.1Zm0-1.6v-.09H6.85v.14Zm.14,2h0v-.1H6.89l.1.1Zm11-21.35.05-.1h-.14l-.05.05h.05Z"/>
      <path class="cls-1" d="M38.76,34.88l-.61-1.12-1.08-1.5-.61-.8-1.22-1.69-1.12-1.5-.8-1.08-.52-.43-.61-.7-2-2.4-.46-.51h-.21v2.83l-.09,1.6v1.08l-.1.56v4.32l-.33.61-.47.14-.37-.1-.42.14-.14.1-.38-.1-.47.29-.23-.15h-.1l-.23.15-.24-.05h-.33l-.33.14-.28-.09-.28.14-.19-.1-.19.1-.66.09-.55-.09h-.52l-.19-.1V25.37l.09-.61V17.52l.1-1.18V13.53l.05-.61V8.04l.1-.75-.1-.38V3.36l-.1-.85V1.95l.09-.1h1.46l.09-.09.33.05h.52l.23-.14.42.05.28-.05h1.27l.23-.14h1.13l.28.1.38-.05.28.05h1.41l.56-.14.47.14.42-.05,1.69.09.47.1.65.09.57.19.33.05.32.23,1.22.42,1.08.66.66.56.47.61.47.94.47,1.27.37,1.17.24.7.14.38.09.75.28,1,.1.52-.1.7.19.66v.19l-.14.51v.61l-.18.33-.19.89-.42,1-.24.52-.8,1.17-.18.33-.66.61-.47.56-.42.33-.66.47-.42.38.23.51.24.43.65.75,1.04,1.5,1.11,1.55.84,1.12.71,1,1.12,1.55.8,1.22.42.85.38.7-.27.31-.38.33-.28.14h-.14l-.09.24-.14.23h-.47l-.23.47h-.27l-.05.14-.28.14h-.33l-.14.29h-.14l-.28.14-.14.24-.19.05-.23.18-.28.24-.19.09-.14.1h-.19v.14l-.23.09h-.52Zm-7.93-19h.89l1.18-.1.28-.18.61-.38.75-.61.56-.56.33-.75v-.89l-.19-1.32-.56-1.4-.57-.38-.49-.24-.91-.31-.85-.09h-2l-.1.61v3.85l-.14,1.63v1l.14.15Z"/>
      <path class="cls-1" d="M50.26,35.3H48.61l-.46.14-.33-.14h-.47l-.75.09h-.28l-.43-.05-.27-.18h-.19l-.05-.4.14-.57v-.7l.1-.94V28.66l.09-.28V24.94l.09-1.59V20.43l.05-1.22v-3.9l-.09-1.64V11.89l-.09-1.64v-.9l.09-1.73.1-.85V2.32l.33-.1.18-.18h.66l.66-.05h.51l.24-.05h.84l.19-.05h.66l.61-.05.28-.14.6.06.85-.1h.8l.37-.1h.47l1.36.09,2.25.09h.66l.56.05.66.14.89-.05h2.25l.19-.05.42.05h.75l.85.05h.84l.19.28.19.33.09.47.28.32.28,1.22-.14.57-.09.33v.23l.09.47.19.47.14.23-.28.19-.37.19-.43.61-.56.61-.33.28-.23.14-.29-.11-.24-.14H62.31l-.23.14H53.4v5.38l.19.09h4.55l1.41.09h.52l.42.14h1.26l.75.19.12-.09h.42l.47.46.1.38v.47l.33.37-.18.38.18.33v.8l.19.32-.19.29-.09.37-.05.38v.28l.18.14.14.14-.14.19h-.16l-.38.28-.33.56.14.28v.14l-.56.14-.33.38h-.8l-3.66-.09H56.36l-2.07.1-1.17.11-.38.23v.28l-.1,1v4.6h1.94l.47.1h1.36l.56.09,1.17.14h4.41l.61-.09h1.51l.56.09.19.29.14.37v.38l.29.23v.24l-.19.23.19.19.09.33-.32.1-.14.05v.15l.24.19.09.32-.19.49-.09.61-.19.33.33.24.19.14-.09.09-.38.19-.42.28-.14.38v.46l-.94.47H62L59.94,35H58.48l-.37.1-.61.05H50.55l-.18.19h0Z"/>
      <path class="cls-1" d="M73.62,35.3H71.97l-.47.14-.32-.14h-.47l-.75.09h-.29l-.42-.05-.23-.14h-.19l-.13-.44.14-.57v-.7l.1-.94V28.66l.1-.28V24.94l.09-1.59V15.31l-.09-1.64V11.89l-.09-1.64v-.9l.09-1.73.1-.85V2.32l.32-.1.19-.18h.66l.66-.05h.65l.24-.05h.84l.19-.05h.66l.61-.05.28-.14.51.05.85-.1h.8l.37-.1h.47l1.36.09,2.25.09h.66l.56.05.66.14.89-.05h2.25l.19-.05.42.05h.75l.85.05h.84l.19.28.19.33.09.47.28.32.28,1.22-.14.57-.09.33v.23l.09.47.19.47.14.23-.28.19-.38.19-.42.61-.56.61-.33.28-.23.14-.33-.05-.24-.14H85.6l-.23.14h-.28l-1.08.05H76.72v5.28l.19.09h4.55l1.41.09h.51l.43.14h1.23l.75.19.19-.05h.42l.47.46.1.38v.47l.33.37-.19.38.19.33v.8l.19.32-.19.29-.09.37v.66l.19.14.14.14-.14.19h-.19l-.37.28-.33.56.14.28v.14l-.56.14-.33.38h-.8l-3.66-.09H79.75l-2.06.1-1.25.07-.38.23v.28l-.1,1v4.6H77.9l.47.1h1.25l.56.09,1.17.14h4.41l.61-.09h1.51l.56.09.19.29.14.37v.38l.29.23v.24l-.19.23.19.19.09.33-.19.09-.14.05v.16l.24.19.09.32-.19.47-.09.61-.19.33.33.24.19.14-.09.09-.38.19-.42.28-.14.38v.46l-.94.47H85.34l-2.06.05H81.82l-.37.1-.61.05H73.87l-.18.19h0Z"/>
      <path class="cls-1" d="M115.76,35.63l-1.27-.1-1-.18-1.17-.47-1.22-.28-.84-.38-1-.52-.75-.42-2.25-1.45-.66-.75-.47-.47-.23-.47-.14-.1-.47-.7-.67-1.13-.7-1.5-.57-1.54-.32-.9-.19-.28-.14-.56-.24-.85-.28-1.22-.14-1.17-.09-1V16.75l.19-1.31.19-.71.09-.47.14-.47.09-.56.47-1.45.61-1.65.33-.7.28-.51.57-1.08.94-1.22.84-.9.94-.89.65-.61.9-.65.79-.57,1.65-.84,1.78-.66,1.55-.47L115.4.85h2.63l1.88.23,1,.19,1,.37,1.41.43.61.23.23.23-.09.19-.1.14.14.19-.14.42-.18.38-.14.09.09.14v.24l.09.09V4.5l-.14.19-.38.05-.28-.05.28.24.24.42-.14.14h-.19v.23l-.14.38-.14.18.09.24-.19.23-.09.05.09.09v.29l-.19.32-.09.33-.14.57-.38.56-.61.28-.65-.19-.47-.23-.89-.19-1.6-.28-1-.09-1.22.09-.75.19-.52.14h-.23l-.33.14-.42.23-1,.61-.76.61-.18.24-.33.28-.52.66-.42.65-.28.57-.33.61-.33.75-.19.56-.23.89-.19.75-.14.28-.09.57-.1.42v1.12l.19,1.32v.7l.14.56.24,1,.28,1,.47,1.22.89,1.12,1.31,1,1.55.84.8.33.61.14h.61l.33.09,1.08.14.37-.09.66-.09h.83l.7-.28.52-.29.42-.28.66-.42.84-.7.85-.66.37-.23h.29v.23l.33.23.14.33.14.28h.42v.23l-.1.24.33.23h.28l-.18.23-.14.24H125l-.14.33-.09.37.18.14.47-.28.24.1-.05.23-.19.24v.09h.05l.19-.14h.23l-.09.19-.14.23v.14h.18l.14-.09.29-.1.09.15-.14.14-.19.23.09.09h.24v.38l.14.09.14.33v.42l-.66.66-.09.14v.05l.14-.05.15.1-.47.56-.9.75-.75.61-.42.23-.65.47-1,.61-.56.28-.94.24-1.36.28-1.17.09h-1.13Zm8.11-2.35h-.19l.05.1Zm1-.75.66-.56.33-.33-.09-.05v.1l-.43.28-.37.33-.28.18v.14Z"/>
      <path class="cls-1" d="M136.31,36.47l-1.22-.37-1-.43-1-.56-1.27-.84-.8-.61-.89-.85-.75-.8-.56-.84-.66-.94-.52-1-.28-.66-.61-1.27-.32-.94-.47-2-.24-1.78V20.99l.05-1.55.09-.8v-.65l.14-1.32.14-.75.19-.94.28-.79.23-1.18.24-.79.28-.52.09-.47.14-.52.19-.51.1-.33.23-.38.24-.7.28-.56.18-.52.47-.56.24-.75.56-.56.47-.76.84-1,1-.89,1-.75.75-.52,1.36-.61,1.22-.46,2.21-.52.84-.14L142.49,0l.71.05,1,.14,1,.28.89.38,1.69.89,1.31,1.12,1.13,1.22,1.17,1.64.71,1.55.56,1.55.28,1.22.14.89.19,1.22V13l.1,1v4.76l-.1,1.27-.14,1.08-.14.75-.14.14-.23.89-.24,1.18v.47l-.23.37.09.42-.14.33-.19.8-.07,1.3-.47,1.5-.42,1.22-.1.42-.23.28-.33.56-.38.52-.23.47-.38.19-.93.93-1.46,1-.84.37-1,.38-1,.23-.84.14-.8.24h-3.14l-1.08.24Zm4.17-7.32.71-.14h.28l.28-.09.19-.19.47-.19.56-.23.19-.19.33-.37.37-.52.42-.56.28-.94.29-.75.09-.47.14-.33v-.52l.19-.51.19-.28-.05-.24V22.3l.1-.33.23-.32-.14-.38.23-.42v-.38l.09-.61v-.84l.14-.66-.05-.47.1-1.08V14.4l-.1-.84v-.75l-.05-.38-.24-.79-.51-1.41-.47-.75-1.12-1.2-.89-.52-1.11-.28-1.74.23-.75.24h-.51l-.24.09-.47.24-.61.46-.37.29-.1.28-.42.33-.61.89-.37.61-.14.37-.24.57-.09.46-.19.43-.15.79-.19.66-.09.42-.19.66-.14,1.13-.19,1.64-.09,1.41.14,1.17.09,1.12.19,1.27.28.89.14.52.33.61.71,1.08,1.5,1.31,1.5.89.75.33.19.1-.1.18.19.1Zm-.56.52-.14-.14h-.16l-.09.09h.19l.19.14Zm.7.09v-.1h-.14v.1Zm.61-.29h0l-.14.19Zm4.5-5.91.05-.14.09-.33v-.32h0l-.09.23-.05.28-.09.38h.09Z"/>
      <path class="cls-1" d="M166.01,36.05h-2.63l-.33-.19-.94-.23-1-.47-1-.66-1.27-.7-.8-.71-.42-.47-.47-.56-.47-.94-.42-.75-.24-.75-.4-1.26-.14-1.55V23.95l-.09-.61V20.88l.05-1.74.09-1.12V16.76l.05-1.46.1-1.83V8.84l.09-.85.14-1.73.05-.89.05-1.51V2.74l.05-.7.28-.29.43-.09.18.09h.33l.28.09.24-.14.47.09.18.14h.1l.23-.19.28.09h.43l.47.09.28-.19h.18l.19.1.33-.05h.99l.37.09h.89l.1.61V3.76l-.05,1.55v5.77l-.05.38.1,1.17v6.1l-.1.7-.09,1.65-.1,2.11-.09,1.57-.09.75.09.61.19.84.61.61.89.71,1.31.33.89.14.85.19.8-.05.79-.42.76-.52.37-.14.38-.47.47-1,.32-1.12.1-.89.09-1,.09-.47-.09-.23v-1l.09-1.32V11.47l.05-.38.05-.56-.05-.8V8.76l.05-1.12-.05-1.41V5.18l.05-.42-.14-.47.09-.47-.14-.47.09-.52-.09-.37-.05-.33.1-.19.23-.05.33-.14.42.05.47.09.33-.14.19.05.23-.05.24.1.28-.14.23.14.71-.05.7-.09.38.09.65-.09,1.13.14.37-.1.29.14v1l.09,1.74v5l-.09,3.13v3.56l.09,1.6v4.6l-.09.56v.85l-.1,1.68-.24,1.24-.1.75-.32.71-.47,1-.71,1.32-.89,1-.56.71-.61.65-.89.57-.61.32-.33.33-.33.24-.94.42-.75.28-.94.38-.55.23-.7.14-.42.1-.24.14h-.33Z"/>
      <path class="cls-1" d="M201.43,35.35l-.19-.61h-.28l-.47.19-.28-.1-.19.14-.23-.09-.38-.33-.61-1-.75-1.51-.65-1.45-.52-.89-.47-1-.94-1.73-.89-1.6-1-2-.75-1.36-.52-.84-1.13-1.79-.77-1.62-.71-1.27-.47-.75-.51-.8v-.09h-.1l-.05.28v4.85l-.09,1.17v5.33l-.05,1-.19.23.1.28v.38l-.1.28.1.38v.23l-.09.38-.38.14-.05.23.15.19.09.14.14-.05h.14l-.05.24-.28.09-.33.09-.14.33.14.24h.05l.09.14.05.1-.09.37v.29l.14-.1.14.1-.09.23-.15.23.1.47.05.47v.18l.18-.05.14.38v.37l-.23.1-.28.14h-.42l-.52.14h-.38l-.28.09h-.37l-.28.09-.19-.05-.38.19-.37.28-.33-.23h-.14l-.19.38h-.09l-.19-.1-.14.1h-1.17l-.24.1-.23-.1-.28-.18v-.66l.23-.52.33-.14.19-.14.05-.14-.14-.19-.43.05-.23-.52.05-.7v-.84l-.1-.57v-.79l.1-1.08-.1-1.13.1-1-.1-1.28V23.08l.1-2-.1-1.88v-.89l-.05-.85v-.89l.05-2V11.23l.05-1.08v-.7l.1-1.27.09-.75.05-.47V5.79l.1-.8.14-1.64V2.5l.05-.14.23-.09.47-.09.47.09.47-.05h.37l.28.05.24-.09.56.18.19-.14h.37l.43-.05.37.09h.38l.19-.14.28.05.18.05.19-.1h.28l.33.05.33-.05.23.05.29-.14.51.37.75,1,1,1.54,1.07,1.74.47.84.52.8,1,1.69.46.8,1.13,1.83.89,1.64.61,1.27.89,1.45.47.8.57.89h.09v-.51l.05-.38-.05-.23.05-.47v-.94l-.09-.38.05-.61-.14-.37.23-.94-.14-.47v-.52l.09-.61V11.18l.05-.37-.05-.28.05-1.08.05-.94V3.82l.14-1,.05-.43.47-.14h2.28l.28.09.29-.09h.42l.37-.14h.42l.66-.09.38.05.37-.05h.66l.38-.05.28.1h.23V4.76l-.1,1.69V7.81l-.1,1.68-.05.94-.09,1.6-.05.84v1l-.1,1.36v2.39l-.05,1.5V23.3l.09,1.36V30.4l-.05,1-.14,1.45v1.18l-.14.65-.28.1h-.75l-.28.18h-.14l-.24-.1-.19-.09-.14.19-.09.09-.05-.05-.37-.14-.38.38h-.23l-.24-.28-.23-.05-.19.29h-.23l-.1-.24-.05-.05h-.09l-.14.23-.09.52-.1.37h-.51Z"/>
      <path class="cls-1" d="M214.9,35.63l-.8-.24-.66-.33h-.61l-.61-.3-1-.47-.61-.33-.38-.28-.61-.38-.42-.33-.42-.23-.33-.33-.47-.38-.37-.42v-.19l-.09-.23v-.23l.14-.19.28-.24.19-.23v-.24l.09-.14.33-.32h.14l.05-.28.19-.19v-.19l.1-.14.37-.33.19-.47.24-.09.14-.42.18-.38.24-.14v-.28l.09-.09.14-.29H211l.23.18.14.15v-.05l-.1-.1v-.18l.28-.14.94.61,1.17.79.89.52.85.14h1l1.22-.47.75-.47.66-1.08.51-1.55v-.94l-.19-.37-.24-.52-.18-.56-.19-.24-.24-.28-.32-.18-.24-.24-.33-.42-.32-.38-.38-.33-.23-.28-.33-.23-1.46-1.22-.94-1-.65-.75-1.32-1.54-.93-1.65-.29-.75-.14-1.17-.09-1.32V8.56l.18-.75.61-1.18.38-.61.33-.47.23-.32.66-.71.61-.61.61-.51.75-.57.94-.61,1.22-.42.65-.28,1-.33,2-.23,2.2.09,1.32.14.46.09.29.15v.18h-.14l-.09.09.14.19.23.1v.37l-.14.24.18.65h-.18l.09.14V4l.14.28v.14l-.19.05-.05.19.28.28-.14.14.14.14v.54l-.24.1v.09l.14.14.28.14.15.14v.1h-.15l-.23.1.05.37v.38l-.14.18v.52l-.14.61-.15.19-.32-.24-1.13-.32-.84-.15h-1.8l-1.13.38-.33.28-.37.28-.43.47-.18,1,.56.94,1,.94,1.27,1.22.8.7.75.8,1.31,1.32,1.32,1.59,1.17,1.6.38.79.61,1.74.32,1.69.1,1.12-.1,1.18-.56,1.78-.47,1-1,1.68-.85.94-.51.42-.33.43-1.36,1.17-.89.56-1,.52-1.17.42-.94.28-.75.14-1.69.14Z"/>
      <path class="cls-1" d="M233.67,35.3h-1.65l-.46.14-.33-.14h-.47l-.75.09h-.28l-.43-.05-.23-.14h-.19l-.09-.37.14-.57.05-.7.09-.94.05-1.27V28.73l.09-.28V25.01l.09-1.59V20.43l.05-1.22v-3.9l-.09-1.64V11.89l-.09-1.64v-.9l.09-1.73.1-.85V2.32l.33-.1.18-.18h.66l.66-.05h.51l.24-.05h.84l.19-.05h.66l.61-.05.28-.14.51.05.85-.1h.8l.37-.1h.47l1.36.09,2.25.09h.66l.56.05.66.14.89-.05h2.25l.19-.05.42.05h.75l.85.05h.84l.19.28.19.33.09.47.28.32.29,1.22-.15.57-.09.33v.23l.09.47.19.47.14.23-.28.19-.37.19-.43.61-.56.61-.33.28-.23.14-.33-.05-.24-.14h-1.97l-.23.14h-.28l-1.08.05h-7.32l-.05.33v4.95l.19.09h4.55l1.41.09h.52l.42.14h1.26l.76.19.18-.05h.43l.46.46.1.38.05.47.32.37-.18.38.18.33v.8l.19.32-.19.29-.09.37-.05.38v.28l.18.14.14.14-.14.19h-.18l-.38.28-.33.56.14.28v.14l-.56.14-.33.38h-.8l-3.66-.09h-1.54l-2.07.1-1.17.14-.38.23v.28l-.1,1v1.78l-.05,1.22-.05.89.05.71h1.94l.47.1h1.36l.56.09,1.18.14h4.41l.61-.09h1.5l.56.09.19.29.14.37v.38l.29.23-.05.24-.19.23.19.19.09.33-.18.09-.15.05v.09l.24.19.09.32-.18.47-.1.61-.19.33.33.24.19.14-.09.09-.38.19-.42.28-.14.38v.46l-.94.47h-1.89l-2.06.05h-1.46l-.37.1-.61.05h-6.97l-.19.19h-.05Z"/>
      <path class="cls-1" d="M262.8,34.22l-.46-.05h-2.82l-.66.05h-1.24l-1.87-.05h-1.74l-.14.05h-1l-.57-.23-.14-.43V32.48l.14-.32v-5.5l.05-1V24.2l.05-.89v-1l.05-1.41V18.88l.05-1,.09-.56V15.47l.1-1.6.14-2,.09-1.64V7.46l.1-1.31-.1-.8V2.79l.09-.38V1.66l.14-.51.38-.05h.56l.47-.05h.24l.14.09.14-.09.28.09.28-.05.19-.09.28.19h.09l.14-.09h.38l.33-.19h.47l.47-.09h.32l.19-.1.19-.05.19.05.09.1H260l.14.19.19-.1.09.14v.71l.05,1,.09.47V4.49l-.05,1.41-.09,1h-.19l-.14.52-.01,1.34v.85l-.09,1.15-.09,1.46v.56l-.05.75-.05,1v1.31l-.1,1.22v2.06l-.05,1.18-.09,1.17-.1.89v.94l-.15.23.1.47-.1.71v.56l-.18.42.09.56-.05.43.24.32h.51l.47.05.52-.05h1.17l1.32.1.89.09h.94l.37.09h.56l.94.09h.61l.28-.05h2.82l.05.28v1.17l-.18.43.18.09.14.14v.14l-.14.28.05.24-.09.09-.05.14v.33l-.19.24.19.09.33.09.19.19-.24.14-.23.28.09.24.23.09v.09l-.14.1-.18.19v.37l-.14.14-.09.14.05.28-.24.38-.23.23-.19.05-.52-.05-.61.05-.23.24-.38.14-.37-.1-.05-.14h-.28l-.14.14-.14-.14-.38-.09h-.56l-.19.18h-.14l-.47-.18h-1.17l-.28.23h-.82Z"/>
      <path class="cls-1" d="M273.78,35.82l-.09-.61-.52-.1h-.23l-.23-.33-.24-.56-.09-.61-.24-.33v-.89l.14-.24v-.23l.18-.38v-.7l-.14-.33V29.2l.05-.56v-.19l-.05-.56V26.67l.14-.47-.09-.94V19.3l-.14-.37.05-.43-.1-.42.05-1.17.05-.89.18-2.44.1-.8V9.21l-.05-1.54V5.88l.05-2.25V2.22l.23-.37.28-.1.33.1.47-.05h2.21l.18-.05.19.1.17005-.09.23.05.33.05.33-.14.23.14.19-.1.38-.09.42.19h.33l.28-.1.33.1v1l.05.89V4.93l.05.51-.05.71.05.65V8.73l-.1.33.05.14h.19l-.05.28-.23.09h-.1l.1.23.09.33.09.28v1.46l-.09.47-.09.23-.14.05-.1.23v.47l.05.24h.14l.09.1v.61h-.14l-.05.19-.05.14.05.32.05.29.14.09v.19l-.14.18v1.46l.18.14-.09.19v.8l.19.89.09,1,.05,1.41v5.03l-.1.8v1.17l-.05.89-.05,1.36L279.62,32.06v.47l-.19.66v.47l-.24.28-.33.23h-.28l-.33.18-.09.29h-.19l-.23-.09-.24.14h-.23l-.24.1-.18-.14-.14-.05-.14.28-.1.42v-.23l-.14-.47h-.19l-.14.19-.37.37-.05.19h0l-.09-.19-.24-.14-.18.19-.1.56v.38h-.05l-.09-.28V35.5l-.1-.14-.19.09-.23.09-.09.29-.1.65-.09.42h-.1Zm5.73-31V4.76l-.1.09v.09Zm.19,6.62v-.1l-.05-.09h-.09v.1l.09.09Z"/>
      <path class="cls-1" d="M302.36,35.35l-.19-.61h-.28l-.47.19-.28-.1-.19.14-.24-.09-.37-.33-.61-1-.75-1.51-.66-1.45-.51-.89-.47-1-.94-1.73-.89-1.6-1-2-.75-1.36-.51-.84-1.13-1.79-.8-1.59-.7-1.27-.47-.75-.52-.8v-.09h-.1l-.05.28v4.85l-.09,1.17v2.58l-.05.89v2.83l-.19.23.09.28.05.19-.05.19-.09.28.09.38v.23l-.1.38-.52.12-.05.23.14.19.1.14.14-.05h.14l-.05.24-.28.09-.33.09-.14.33.14.24h0l.09.14.05.1-.1.37v.29l.14-.1.15.1-.1.23-.14.23.09.47.05.47v.2l.19-.05.14.38v.37l-.23.1-.29.14h-.42l-.51.14h-.38l-.28.09h-.38l-.28.09-.19-.05-.37.19-.38.28-.33-.23h-.14l-.18.38h-.01l-.19-.1-.14.1h-1.08l-.23.1-.23-.1-.29-.18v-.66l.24-.52.33-.14.18-.14.05-.14-.14-.19-.42.05-.24-.52.05-.7v-.84l-.09-.57v-.79l.09-1.08-.09-1.13.09-1L282.46,25.76V23.08l.09-2-.09-1.88v-.89l-.05-.85v-.89l.05-2V11.23l.05-1.08.05-.7.09-1.27.1-.75V6.96l.05-1.17.09-.8.14-1.64V2.5l.05-.14.24-.09.47-.09.46.09.47-.05h.38l.28.05.23-.09.57.18.19-.14h.37l.42-.05.38.09h.37l.19-.14.28.05.19.05.19-.1h.28l.33.05.33-.05.23.05.28-.14.52.37.75,1,1,1.54L293.62,6.68l.47.84.52.8,1,1.69.47.8,1.13,1.83.89,1.64.61,1.27L299.6,17l.47.8.56.89h.09v-.51l.05-.38-.05-.23.05-.47.05-.52v-.42l-.1-.38.05-.61-.15-.41.24-.94-.15-.47v-.52l.1-.61-.05-.61.05-.47.05-.37-.05-.28.05-1.08V7.53l.05-.94.05-1.45-.05-1.36.14-1V2.35l.47-.14h2.28l.28.09.28-.09h.42l.38-.14h.46l.66-.09.37.05.38-.05h.47l.37-.05.28.1h.24v.1l-.05.75V4.76l-.09,1.69V7.81l-.09,1.68-.05.94-.09,1.6-.05.84-.05,1-.09,1.36-.05,1.5v3.84L307.62,22.4v.94l.1,1.36v2.64l-.05.8v1.22l-.05.65v1.43l-.14,1.45v1.18l-.14.65-.29.1h-.75l-.28.18h-.14l-.23-.1-.19-.09-.14.19-.1.09v-.05l-.38-.14-.37.38h-.24l-.23-.28-.24-.05-.18.29h-.24l-.09-.24-.05-.05h-.09l-.14.23-.1.52-.09.37h-.14Z"/>
      <path class="cls-1" d="M322.02,36.52l-1.27-.24h-.47l-.66-.28-.42-.18-.42-.15-.66-.32-.51-.14-.24-.29h-.33l-.14-.14-.47-.38-.84-.8-.56-.61-.33-.14-.19-.28-.52-.66-.37-.75-.19-.42-.19-.33-.47-.56-.32-.94-.33-.7-.14-.42V27.5l-.09-.23-.14-.19-.09-.42-.14-.38-.1-.75-.28-1.08-.09-1-.15-.47v-1l-.38-1.64-.23-1.17.09-.56v-1.7l.14-.61v-.85l.18-.56V13.62l-.09-.18.18-.33.1-.33-.14-.14.18-.38v-.7l.19-.47.14-.38.19-.65.1-.52.28-.75.28-1,.33-.38.37-.65.56-1,.24-.51.28-.19,1-1,.71-.56.75-.66.65-.42,1.65-.8,1.08-.28h.18l.52-.14.66-.14.42-.18h.33l.28-.09h.37l.38-.15h.51l.29-.09h.37l.38-.05h.75l1.45.14h.33l.38.15,1.17.23.33.05.61.14.51.19,1,.42.89.51,1.13.75.42.33.09.24-.09.28-.19.42-.33.24-.19.23V5.2l-.24.14-.18.14v.33l-.24.28-.28.18.05.19-.05.05h-.12l-.19-.05-.1.33.1.28h-.38l-.14.24-.23.18-.05.29-.23.09-.15.19-.14.28-.18.19-.14.18-.24.15-.28.28h-.38l-.61-.42-.65-.42-.61-.28-1.32-.28h-1.17l-.28-.09h.05l-.24-.05-1.41.19-.28.1-.19.23-.89.28-1,.61-.71.66-.7,1.41-.52,1.59-.23,1-.05.84-.09,1-.09.89-.1.51.1,1.08.09.8v.33l.09,1.36.24,1.17.19.89.18.75.33,1,.38,1,.37.8.52,1,1.08.85.84.37.85.33.94.28h1.21l.71-.09.47-.19.47-.38.42-.46.05-.33-.05-1L327.23,24.76l-.15-1.41v-.94l-.09-.32h-.42l-3.38.37-1.41.19h-.37l-.05-.09.09-.09.05-.1-.19-.28.05-.19v-.18l-.05-.33.14-.52V19.18l.05-.18-.05-.33V18.1l-.09-.28.09-1,.1-.19-.1-.33.1-.42v-.14l.66-.28,1.36-.28,2.49-.1h4.88l.28.14.28-.19h.38l.47-.1.32.05.19-.09h.61l.42-.09h.38l.14.37.09,1.13v1.41l-.05,1.5-.05,2v2.2l.09,1.5.05.94v1.41l-.19.94-.05,1-.14.66-.33.19-.28.8-.33.65-.09.14-.19.38-.37.51-.38.47-.75.8-1.08.89-.7.42-.24.24-.47.28-.56.33-.23.28h-.38l-.37.09h-.33l-.38.24-.56.09-.38-.09h-.42l-.61.05-.51-.1-.52.1-.56-.1-.47.14h-.28l-.05.09Z"/>
      <path class="cls-1" d="M349.89,35.06h0l-.09.15-.24-.05h-.32l-.47.19-.33-.1-.14-.09-.19.09-.38.1-.14.09-.37-.09-.43-.05-.18-.14-.19-.19-.05-.28.05-.56-.14-.43-.05-1-.09-1.59-.05-1.08v-.57l-.05-1.07-.14-1.83v-.94l.09-.47V20.24l.05-1.22v-.94l-.05-1.17.09-.71.05-1.54.05-1.08V10.67l-.05-1.78V7.95l.05-1.41V2.6l.14-.33.32-.14.38.05.33-.1.23.1.71-.05.75.05h1.55l.46-.09.85-.05.7-.23.85-.05.89.05.56-.05.56.05.85-.05h.94l.28.05h.37l.33-.05.52.09h1.41l.42.09.37-.14.75.09h1.83l.57-.14.89.09h.63l.28.14.09.33.24.38-.05.42.19.47.23.51-.14.28.05.43v.42l-.14.33v.37l.1.33-.33.38v.56l-.33.33-.28.23-.38.14h-.09l.14.24-.09.19-.28-.1-.24.19h-1.45l-.24.09-.33-.09h-.33l-.18.18-.14.1h-.24l-.09-.14h-.85l-.33-.14-.28.09h-.7l.05.09h.1399l-.05.1h-.23l-.42-.05h-.71l-.28.09h-.61l-.19-.05-.32.14-.8.05h-1.18l-.93.1-.33.32v3.66l-.05,1.13.05.19h2.05l.33.09h1.67l.52-.05h1.07l.52.1h.47l1.31.09h.66l.75.09.05.09.05.29.14.18v.61l.09.28v.57l.14.23.05.19-.1.51v.52l-.1.42.1.24-.1.14-.18.14v.28l.24.14h.28l.09.19-.47.09-.56.33-.19.33h-.19l-.14.19-.14.14.14.1h.24l-.1.19-.42.19-.23.28-.28-.05h-.24l-.28.09h-.38l-.37-.09-.38.09-.51.14h-.33l-.38.05H355.91l-.7.09h-1.69l-.19.05v1.12l-.09.85v3.89l.1.8-.1.24v1.4l-.15.47v.42l-.09-.28v-.42h0l-.1.09v.38l.09.37-.09.47v1.36l.19.1.14-.14h.1v.19l-.1.19.14.28-.47.19.05.19v.18l-.23.1-.19.19-.1-.1h-.14l-.14.14-.23.14h-.33l-.14.18-.14-.14-.28.14-.19.15-.38.09-.09.23h-.05Zm2.86-5.86v-.14h-.14l-.05.09.05.09.09.1Zm0-1.6v-.09h-.05v.14Zm.14,2h0v-.1h-.14l.1.1Zm11-21.35.05-.1h-.14l-.05.05h.05Z"/>
      <path class="cls-1" d="M375.98,36.47l-1.22-.37-1-.43-1-.56-1.27-.84-.8-.61-.89-.85-.75-.8-.56-.84-.66-.94-.52-1-.28-.66-.61-1.27-.33-.94-.47-2-.23-1.78V20.99l.05-1.55.09-.8.05-.65.14-1.32.14-.75.19-.94.28-.79.23-1.18.24-.79.28-.52.09-.47.14-.52.19-.51.09-.33.24-.38.23-.7.29-.56.18-.52.47-.56.24-.75.56-.56.47-.76.84-1,1-.89,1-.75.75-.52,1.36-.61,1.22-.46,2.21-.52.84-.14L382.21,0l.71.05,1,.14,1,.28.89.38,1.69.89,1.31,1.12,1.13,1.22,1.17,1.64.7,1.55.57,1.55.28,1.22.14.89.19,1.22V13l.1,1v4.76l-.1,1.27-.14,1.08-.14.75-.14.14-.23.89-.24,1.18v.47l-.24.37.1.42-.14.33-.19.8-.12,1.3-.47,1.5-.42,1.21-.09.42-.23.28-.33.56-.38.52-.23.47-.38.19-.94.93-1.45,1-.85.37-1,.38-1,.23-.84.14-.8.24h-3.15l-1.07.24Zm4.17-7.32.7-.14h.29l.28-.09.2-.16.46-.19.57-.23.19-.19.32-.37.38-.52.42-.56.28-.94.28-.75.1-.47.14-.33v-.55l.19-.51.18-.28V22.3l.09-.33.24-.32-.14-.38.23-.42v-.38l.09-.61.05-.84.14-.66-.05-.47.09-1.08V15.76l.05-.51-.05-.85-.09-.84V12.43l-.24-.79-.51-1.41-.47-.75-1.08-1.17-.89-.52-1.08-.28-1.74.23-.75.24h-.51l-.24.09-.47.24-.61.46-.37.29-.1.28-.42.33-.61.89-.38.61-.14.37-.23.57-.09.46-.19.43-.19.79-.19.66-.09.42-.19.66-.38,1.1-.19,1.64-.09,1.41.14,1.17.09,1.12.19,1.27.28.89.14.52.33.61.71,1.08,1.5,1.31,1.5.89.75.33.19.1-.1.18.19.1Zm-.56.52-.14-.14h-.19l-.09.09h.19l.19.14Zm.66.05v-.1h-.14l.05.1Zm.6-.29h0l-.14.19Zm4.51-5.91.05-.14.09-.33.05-.32h-.05l-.09.23-.05.28-.09.38h.09Z"/>
      <path class="cls-1" d="M411.4,34.88l-.61-1.12-1.08-1.5-.61-.8-1.22-1.69-1.13-1.5-.79-1.08-.52-.47-.61-.7-2-2.4-.47-.51h-.18v1.83l-.05,1-.09,1.6v1.08l-.1.56-.05,1.27V33.5l-.33.61-.47.14-.37-.1-.42.14-.14.1-.38-.1-.47.29-.23-.15h-.1l-.23.15-.24-.05h-.18l-.33.14-.28-.09-.29.14-.18-.1-.19.1-.66.09-.51-.09h-.52l-.19-.1V28.09l.05-.84.05-1.17v-.75l.09-.61v-1l-.05-1V21.51l.05-1.18.05-.61v-2.2l.1-1.18v-.89l-.05-.79.05-1.13V11.98l.05-.89V9.17l.05-.41-.05-.7.09-.75-.09-.38V4.76l-.05-1.4-.09-.85V2.04l.05-.09.09-.1h1.46l.09-.09.33.05h.51l.24-.14.42005.09.28-.05h1.27l.23-.14h.85l.28.1.37-.05.29.05h1.4l.57-.14.46.23.42-.05,1.69.09.47.1.65.09.57.19.33.05.32.23,1.22.42,1.08.66.66.56.47.61.47.94.47,1.27.37,1.17.24.7.14.38.09.75.28,1,.1.52-.1.7.19.66-.05.19-.14.51v.61l-.19.33-.18.89-.42,1-.24.52-.8,1.17-.18.33-.66.61-.47.56-.42.33-.66.47-.42.38.23.51.24.43.65.75,1.11,1.37,1.13,1.55.84,1.12.7,1,1.13,1.55.8,1.22.42.85.38.7-.33.28-.38.33-.28.14h-.14l-.09.24-.15.23h-.42l-.23.47h-.28l-.05.14-.28.14h-.33l-.14.29h-.19l-.28.14-.14.24-.19.05-.23.18-.28.24-.19.09-.14.1H412.62l-.05.14-.23.09h-.52Zm-7.93-19h.89l1.17-.1.29-.18.61-.38.75-.61.56-.56.33-.75v-.89l-.18-1.32-.57-1.4-.56-.38-.51-.19-.94-.33-.85-.09h-2l-.1.61v6.45l.14.15Z"/>
      <path class="cls-1" d="M434.44,34.69l-.38-.14V31.36l.09-.42-.09-.66V26.62l-.05-.47V23.98l-.1-2V19.25l.1-3.66V12.97l-.05-.85-.1-1.36V9.64l-.1-.75-.56-.05h-1.17l-1.18.05-2.29.18h-2.02l-.38-.19.1-.24V8.13l.14-.52-.14-.23-.05-.28.23-.38-.14-.28.1-.38V4.89l.14-.52L426.49,4l.1-.38.14-1.12V2.17l.52-.42.84-.1.85.14h1.59l.52.1.7-.1h.61l.47-.09h1.55l.94.05h2.06l.38.05.94-.05h1l1.6-.18,1.4-.14h7.19l.1.56-.14.57.09.42-.09.47.09.33-.14.23-.09.33-.15.14.1.23.05.38.09.19.05.28-.24.19-.14.23.33.42-.33.38-.14.37.05.29.18.18-.32.28-.29.38h-.98l-.47.24-.47.09h-4.52l-.52.19-.56.33v.89l.09,1.5.05,1.55v4.08l-.05.89v7.1l-.09,1.08-.05.89-.05.94v2.16l-.1,1.31.05,1.45-.05.66-.28.19-.61.14-.42.42h-.19l-.14-.37-.23.14-.14.09-.19-.09-.40991.61-.05.14v-.33h-.19l-.09.14-.24.09-.28-.09h-.15l-.19-.14-.18.09h-.9l-.23-.09-.14.09h-.24l-.14.19Z"/>
      <path class="cls-1" d="M455.62,35.3h-1.65l-.47.14-.32-.14h-.47l-.75.09h-.29l-.42-.05-.23-.14h-.19l-.09-.37.14-.57v-.7l.1-.94V28.73l.1-.28v-.94l.05-1v-1.5l.09-1.59V20.43l.05-1.22v-3.9l-.09-1.64V11.89l-.09-1.64v-.9l.09-1.73.1-.85V2.32l.32-.1.19-.18h.66l.66-.05h.51l.24-.05h.84l.19-.05h.66l.61-.05.28-.14.51.05.85-.1h.8l.37-.1h.47l1.36.09,2.25.09h.66l.56.05.66.14.89-.05h2.25l.19-.05.42.05h.75l.85.05h.84l.19.28.19.33.09.47.28.32.28,1.22-.14.57-.09.33v.23l.09.47.19.47.14.23-.28.19-.37.19-.43.61-.56.61-.33.28-.23.14-.33-.05-.24-.14H467.6l-.23.14h-.28l-1.08.05h-7.32l-.05.33v4.95l.19.09h4.55l1.41.09h.51l.43.14h1.26l.75.19.19-.05h.42l.47.46.1.38v.47l.33.37-.19.38.19.33v.8l.19.32-.19.29-.09.37-.05.38-.05.28.19.14.14.14-.14.19h-.19l-.37.28-.33.56.14.28v.14l-.56.14-.33.38h-.8l-3.66-.09h-1.55l-2.06.1-1.17.14-.38.23v.28l-.1,1v.89l-.05.89v2.82h1.94l.47.1h1.36l.56.09,1.17.14h4.41l.61-.09h1.51l.56.09.19.29.14.37v.38l.28.23v.24l-.19.23.19.19.09.33-.19.09-.14.05v.09l.24.19.09.32-.19.47-.09.61-.19.33.33.24.19.14-.1.09-.37.19-.42.28-.14.38v.46l-.94.47h-1.89l-2.06.05h-1.46l-.37.1-.61.05h-6.98l-.18.19h-.05Z"/>
      <path class="cls-1" d="M481.12,34.69l-.37-.14-.05-.14-.08-.75.05-.94v-.56l-.05-.47v-.33l.1-.42-.1-.66V26.15l-.05-1.17v-1l-.09-2V19.25l.09-3.66V12.97l-.05-.85-.1-1.36-.05-1.12-.09-.75-.56-.05h-1.18l-1.17.05-2.3.18h-2.02l-.37-.19.09-.24V8.13l.14-.52-.14-.23V7.1l.23-.38-.14-.28.09-.38V4.89l.14-.52V4l.09-.38.14-1.12.05-.33.51-.42.74.01.84.14h1.6l.51.1.71-.1h.61l.47-.09h1.55l.93.05h2.07l.37.05.94-.05h1l1.59-.18,1.41-.14h7.19l.09.56-.14.57.1.42-.1.47.1.33-.15.23-.09.33-.14.14.09.23.05.38.09.19v.28l-.23.19-.14.23.32.42-.32.38-.15.37v.29l.19.18-.33.28-.28.38h-.99l-.46.24-.47.09h-4.52l-.51.19-.57.33v.89l.1,1.5v8.4l-.05,2,.05,2v1.22l-.1,1.08v.89l-.05.94-.05.89v1.27L487.74,31.76l.05,1.45-.05.66-.28.19-.61.14-.43.42h-.18l-.14-.37-.24.14-.14.09-.19-.09-.18.33-.05.14-.05-.33h-.19l-.09.14-.23.09-.29-.09h-.18l-.19-.14-.19.09H483l-.23-.09-.15.09h-.23l-.14.19Z"/>
      <path class="cls-1" d="M505.19,36.47l-1.22-.37-1-.43-1-.56-1.26-.84-.8-.61-.89-.85-.75-.8-.57-.84-.65-.94-.52-1-.28-.66-.61-1.27-.33-.94-.47-2-.22-1.73V19.44l.1-.8v-.65l.14-1.32.15-.75.18-.94.29-.79.23-1.18.23-.79.29-.52.09-.47.14-.52.19-.51.09-.33.24-.38.23-.7.28-.56.19-.52.47-.56.23-.75.57-.56.47-.76.84-1,1-.89,1-.75.75-.52,1.36-.61,1.22-.46,2.2-.52.85-.14,2.72-.19.7.05,1,.14,1,.28.9.38,1.68.89,1.32,1.12,1.12,1.22,1.18,1.64.7,1.55.6601,1.49.29,1.22.14.89.18,1.22v.85l.09,1v4.82l-.09,1.27-.14,1.08-.14.75-.14.14-.24.89-.23,1.18v.47l-.24.37.1.42-.15.33-.18.8-.15,1.3-.47,1.5-.42,1.22-.09.42-.24.28-.33.56-.37.52-.24.47-.37.19-.94.93-1.45,1-.85.37-1,.38-1,.23-.85.14-.79.24h-3.15l-1.08.24Zm4.18-7.32.7-.14h.28l.28-.09.19-.19.47-.19.56-.23.19-.19.33-.37.38-.52.42-.56.28-.94.28-.75.1-.47.14-.33v-.52l.18-.51.19-.28-.05-.24V22.3l.09-.33.24-.32-.14-.38.23-.42v-.38l.1-.61v-.84l.14-.66v-.47l.09-1.08V14.4l-.09-.84v-.75l-.05-.38-.23-.79-.52-1.41-.47-.75-1.07-1.17L511.62,7.76l-1.08-.28-1.73.23-.75.24h-.52l-.23.09-.47.24-.61.46-.38.29-.09.28-.42.33-.61.89-.38.61-.14.37-.23.57-.1.46-.19.43-.18.79-.19.66-.09.42-.19.66-.14,1.13-.28,1.64v1.41l.14,1.17.09,1.12.19,1.27.28.89.14.52.33.61.7,1.08,1.5,1.31,1.5.89.76.33.18.1-.09.18.19.1Zm-.56.52-.15-.14h-.18l-.1.09h.24l.19.14Zm.65.05v-.1h-.14v.1Zm.61-.29h0l-.15.19Zm4.55-5.91v-.14l.1-.33v-.32h0l-.1.23v.28l-.1.38h.1Z"/>
      <path class="cls-1" d="M544.23,35.35l-.19-.61h-.28l-.47.19-.28-.1-.19.14-.23-.09-.38-.33-.61-1-.75-1.51-.66-1.45-.51-.89-.47-1-.94-1.73-.89-1.6-1-2-.76-1.35-.51-.84-1.13-1.79-.8-1.59-.7-1.27-.47-.75-.51-.8v-.09h-.1v3.33l-.05.8.05,1-.1,1.17v5.29l-.05,1-.19.23.1.28v.38l-.1.28.1.38v.23l-.09.38-.38.14v.23l.14.19.1.14.14-.05h.09v.24l-.28.09-.33.09-.14.33.14.24h0l.09.14v.1l-.09.37v.29l.14-.1.14.1-.1.23-.14.23.1.47v.65l.19-.05.14.38v.37l-.23.1-.28.14h-.43l-.51.14h-.38l-.28.09h-.37l-.29.09-.18-.05-.38.19-.37.28-.33-.23h-.14l-.19.38h-.09l-.19-.1-.14.1h-1.08l-.24.1-.23-.1-.28-.18v-.66l.23-.52.33-.14.19-.14v-.14l-.14-.19-.42.05-.23-.52V31.61l-.09-.57v-.79l.09-1.08-.09-1.13.09-1V25.07l-.05-1.93.09-2-.09-1.88V12.83l.05-1.6V9.45l.09-1.27.1-.75V5.79l.1-.8.14-1.64V2.36l.24-.09.47-.09.47.09.47-.05h.37l.28.05.24-.09.56.18.19-.14h.37l.43-.05.37.09h.38l.18-.14.28.05.19.05.19-.1h.28l.33.05.33-.05.23.05.28-.14.52.37.75,1,1,1.54,1.08,1.74.47.84.52.8,1,1.69.47.8,1.13,1.83.89,1.64.61,1.27.89,1.45.47.8.56.89h.1V16.16l.23011-.4v-.61l-.14-.37.24-.94-.14-.47v-.52l.09-.61V11.16l.05-.37-.05-.28.05-1.08V7.55l.05-.94V3.8l.14-1V2.37l.47-.14h2.28l.28.09.28-.09h.43l.37-.14h.42L548.1,2l.38.05.37-.05h.47l.38-.05.28.1h.23V4.76l-.09,1.69V7.81l-.09,1.68v.94l-.09,1.6v1.84l-.1,1.36v3.89l-.05,1.45V23.3l.1,1.36V26.3l-.05,1v3.1l-.05,1-.14,1.45v1.18l-.14.65-.28.1h-.75l-.28.18h-.15l-.23-.1-.19-.09-.14.19-.09.09V35l-.37-.14-.38.38h-.23l-.24-.28-.23-.05-.19.29h-.24l-.09-.24-.05-.05h-.09l-.14.23-.1.52-.09.37h-.14Z"/>
      <path class="cls-1" d="M37.62,78.42l-.33-.18-.47-1.69-.23-.33-.19-.56-.19-.43-.09-.51-.38-1-.28-.66-.42-1.12-.33-1-.66-2.11L33.39,67l-.51-1.78-.26-.92-.33-1-.42-1.18-.38-1.08-.23-.75-.7-1.87-.75-1.79-.29-.75-.47-1.55-.89-2.81-.18-.56-.36-.92-.24-.8-.33-1v-.38l.09-.14-.09-.51v-.55l.33-.23h.24l.09-.19.42-.14.38-.24v.05h.14l.18-.14.38-.14.28-.24.24-.14.37-.09.7-.42.61-.28.38-.24.56-.28.57-.09.32.23.38.7.47,1.41.37,1.08.29.89.32,1.18.24.56v.37l.23.66.33,1.22.33.84.7,1.83.7,1.88.33.89.57,1.5.56,1.36.28.94.42,1.27.42,1.22.1.09.14-.28.28-.8.24-.93.28-1,.4-.6.33-1.13.37-1.17.24-.84.42-1.32.33-.94.28-1,.23-.84.5-1.36.37-1.07.43-1.22.56-1.46.42-1.12.42-1,.33-.84.28-.43.43-.7.28-.56.23-.05.24.19.23.09.42.14.57.24.42.18.19.1.23-.05.14.14.28.05h.24v.18l.23.1h.52l.37.24h.56l.24.09.14.14h.52l.28.18.09.38-.19.33-.14.23-.14.28v.15l.23-.24.47-.7.05-.14h.09l-.19.56-.61,1.31-.51,1.36-.29.66-.14.61-.37.89-.38,1-.28.8-.33,1-.51,1.59-.28.94-.29.89-.55,1.92L49.2,60.5l-.38,1.17-1.2,3.62-.61,1.64-.32.8-.61,1.69-.66,1.92-.71,1.83-.28.8-.32.84-.49,1.31-.33.8-.14.38-.19.09h-.09v.14l-.1.24v.18l-.05.24-.28.14-.47.09H38.13Zm7.68-7.66h-.09v.19Zm2-5.49v-.19l.09-.14v-.05l-.1.14v.14l-.09.14v.05ZM48.71,61l.09-.38v-.23l.09-.1h0l-.1.09v.52l-.17.26v.23Zm1.27-4v-.09h0V57Zm.33-1.22v-.14l.1-.14-.1.09-.09.19h.09Zm2.2-6.19h0v0Z"/>
      <path class="cls-1" d="M55.35,77.02h-.19l-.28-.19-.28.09v-.14h-.14l-.24.14h-.75l-.23-.09-.19-.19-.14.23-.23.19-.29-.14-.28-.28-.37.05-.24-.14-.14.09-.14.14-.14-.19-.33-.14-.09-.37.23-.85.28-1.4.15-.9.3-1.64.33-1.5.19-.94.18-1,.3-1.44.32-1.5.38-1.83L53.55,62l.38-2.06.23-1.08.39-2.1.23-1.36.29-1.08.42-1.68.37-1.6.43-1.59.33-1.38.46-1.31.28-.79.24-.85.23-.56.05-.23h.19l.19-.14.47.14.37.1.24-.19.42.09.28.14.23-.09.38.09h.37l.38.09.47-.14h.37l.19.09.42-.14h1.46l.42-.09.23.09.24-.18h.33l.09-.05.38.89.33.85.42,1.17.47,1.45.4,1.26.24.79.37,1.46.24.89.14.52.33,1.12.28,1,.28.9.19.89.32.94.24.89.47,1.73.19.89.42,1.88.29,1.44v.37l.19.76.52,2.2.29,1.22.09.61.1.7.18,1.08.24.94v.61l.14.56v.47l-.28.28-.33.15-.43.23-.18.14h-.28l-.33.14-.24.24-.19-.05-.46.28H70.5l-.18.14h-.19l-.14.33-.28.18-.28-.14h-.24l-.37.47-.14-.1-.06-.15-.66.29-.66.14-.28-.33-.28-1.17-.37-1.37-.42-1.56-.33-1.47-.7-.09H59.81l-.71.1-.09.28-.14.56-.14.42-.24,1-.18.9-.24.89-.33,1.08-.33.23-.32.05-.33.23h-.38l-.14.1h-.23l-.28-.24-.19.33-.1.05Zm8.49-12.86-.1-.89L63.46,62l-.14-.61-.09-.37-.28-.94-.19-.94-.23-.84-.24-.94-.14-.42-.09-.1-.1.14-.19.94L61.54,59l-.23,1.17L60.98,62l-.19.85-.17,1.17.52.09h1l1.36.1Z"/>
      <path class="cls-1" d="M84.67,76.87H74.76l-.56-.23-.14-.42-.05-.47.05-.28-.05-.33.15-.33V62.59l.05-1.08v-1l.09-.56.32-.9.05-1,.09-1.59.15-2L75,52.81V50.05l.05-1.29-.09-.8V45.39l.09-.37v-.75l.14-.52.43.01h1.03l.23-.05.14.1.14-.1.29.1h.28l.19-.1.28.19.09.05.14-.09h.38l.33-.19h.48l.47-.1h.33l.19-.09.19-.05.18.05.1.09h.8l.14.19.18-.09.1.14v1.7l.1.47v2.68l-.09,1h-.19l-.14.52v2.15l-.09,1.13-.1,1.45v3.63l-.09,1.22V63l-.1,1.17-.09.9V66l-.14.24.1.47-.1.7v.56l-.19.43.1.56v.42l.24.33h2.26l1.31.09.9.1h.93l.38.09h.56l.94.1h3.71v1.46l-.19.42.19.1.14.14v.14l-.14.28v.23l-.1.1v.47l-.19.23.19.09.32.1.19.19-.23.14-.24.28.1.23.23.1v.09l-.14.09-.19.19v.38l-.14.14-.09.14v.28l-.23.38-.24.23-.18.05-.52-.05-.61.05-.23.23-.38.14-.38-.09V76.9l-.1-.05-.18.05-.14.14-.15-.14-.37-.1h-.56l-.19.19h-.14l-.47-.19H85.98l-.29.23h-.75Z"/>
      <path class="cls-1" d="M104.62,76.87H94.71l-.56-.23-.14-.42V75.14l.14-.33V60.51l.09-.56V58.11l.09-1.59.14-2,.1-1.65V50.11l.09-1.32-.09-.8V45.42l.1-.37V44.3l.14-.52h1.41l.23-.05.14.1.14-.1.28.1h.29l.18-.1.29.19.09.05.14-.09h.38l.32-.19h.92l.47-.1h.33l.19-.09.19-.05.18.05.1.09h.79l.15.19.18-.09.1.14v1.7l.09.47v2.68l-.1,1h-.18l-.14.52-.05,1.31v.84l-.1,1.13-.09,1.45v1.32l-.05,1v1.31l-.09,1.22v3.24l-.1,1.17-.09.9v.93l-.14.24.09.47-.09.7v.56l-.19.43.1.56-.05.42.23.33h2.43l1.31.09.89.1h.94l.38.09h.56l.94.1h3.7v1.13l.05.33-.19.42.19.1.14.14v.14l-.14.28.05.23-.1.1v.47l-.18.23.18.09.33.1.19.19-.23.14-.24.28.1.23.23.1v.09l-.14.09-.19.19.05.38-.14.14-.1.14.05.28-.23.38-.24.23-.19.05-.51-.05-.61.05-.24.23-.37.14-.38-.09v-.14l-.1-.05-.19.05-.14.14-.14-.14-.37-.1h-.57l-.18.19h-.14l-.47-.19h-1.18l-.28.23h-.75Z"/>
      <path class="cls-1" d="M118.83,77.95h-1.64l-.47.14-.33-.14h-.47l-.75.1-.28-.05h-.42l-.24-.14h-.19l-.09-.38.14-.56.05-.71.09-.93V73.26l.05-1.31-.05-.57.09-.28v-.94l.05-1v-1.5l.09-1.6V63.08l.05-1.22V60.27l.05-.89V57.97l-.1-1.64V54.54l-.1-1.64v-.89l.1-1.74.09-.84.05-.8V46.24l-.05-.94v-.33l.33-.09.19-.19h.65l.66-.05h1.97l.47-.05h.61l.28-.14h.52l.84-.09.8-.05.37-.09.47-.05,1.36.09,2.26.1h1.22l.65.14h5.35l.56.05.29-.05.22005.26.19.33.1.47.28.33.28,1.22-.14.56-.1.33v.24l.1.47.19.46.14.24-.29.19-.37.18-.42.61-.57.61-.32.29-.24.14h-.33l-.23-.14h-1.97l-.24.14H122v3.43l-.05,1.31.05.56.18.1h4.56l1.4.1h.48l.42.14h1.27l.75.18h.61l.47.47.09.37v.47l.33.38-.19.37.19.33-.05.33.05.47.18.33-.18.28-.1.38v.65l.19.14.14.14-.14.19h-.19l-.38.28-.32.57.14.28v.08l-.57.14-.33.37h-.79l-3.66-.09H125l-2.07.09-1.17.14-.37.24v.28l-.09,1v.89l-.05.9v1.22l-.05.89.05.7h1.94l.47.09h1.36l.57.1,1.17.14h4.41l.45-.1h1.5l.56.09.19.28.14.38.05.37.28.24-.05.23-.19.24.19.18.1.33-.19.1h-.14v.14l.23.19.1.33-.19.47-.1.61-.18.33.33.23.18.14-.09.1-.38.18-.42.29-.14.37v.47l-.94.47h-1.89l-2.07.05h-1.45l-.38.09h-3.15l-1.55.05h-2.84l-.19.19h0Z"/>
      <path class="cls-1" d="M145.48,78.42l-.23-.33V74.87l-.05-1.08V72.51l.1-.37-.24-1V70.3l.05-.85.09-1.55.05-1.26-.09-1.36-.05-.85.09-1v-.77l-.8-1.36-.65-1-.42-.51-1.51-2.25-.42-.61-.23-.24-.43-.61-.89-1.22-.84-1.22-.66-1-.65-1-.38-.61-.56-.89-.57-.94-.28-.51-.37-.75-.05-.57.33-.23.28-.14.33-.19h.33l.14-.24.47-.23.37-.14.19-.19.28-.05h.1l.09-.1h.23l.29-.1.14-.19.23-.09.19-.09.14-.1.23-.18.14.09.14-.19.33-.28.47-.09.42-.28.38-.1.47.7.66,1.22.7,1,.84,1.36.71,1.12.56.8,1.83,2.53.24.57.56.84.47.85.09.09h.1l.28-.65.38-1.13.23-.61.33-.52.47-.89.19-.61.18-.42.1-.33.28-.14.23-.38v-.42l.42-.94.57-1.17.28-.42.33-.75.37-.8.05-.24.19-.28.09-.37.19-.24.18-.14.33.1h.43l.42.19.42.1.47.18.33.24.18-.1.29.14h.32v.24l.24.09.33-.09.09.14h.28l.24.09h.19l.14.14h.23l.56.14.66.14.38.14v.1l-.1.28-.61,1.12-.56,1.49-.52,1-.56,1.17-1.22,2.44-.71,1.45-.75,1.65-.61,1.45-.89,1.84-.47,1-.7,1.64-.33.8-.09.14-.29.51-.09.8-.05.94v1.45l-.14,1.72v1.22l-.1.75.05,1.36v.94l-.05,1.59v3.52l-.33.14h-.33l-.19.09-.47-.09-.47.14-.51-.09-.14.18h-.24l-.19-.09-.14-.1-.19.1-.14-.05-.23-.09-.19.14h-.75l-.19-.14h-.14l-.37.14h-1.35Zm-.23-6.85h-.05l.05.1Z"/>
      <path class="cls-1" d="M187.14,77.53l-.61-1.08-1.07-1.5-.61-.8-1.23-1.68-1.13-1.51-.8-1.08-.51-.46-.56-.66-2-2.39-.47-.52h-.19v2.83l-.1,1.59v1.08l-.09.57-.05,1.26-.05,1.88v1.17l-.33.61-.47.14-.38-.09-.42.14-.14.09-.37-.09-.47.28-.24-.14h-.23l-.24.14h-.42l-.33.14-.28-.09-.28.14-.19-.09-.19.09-.65.09-.52-.09h-.51l-.19-.09V67.96l.1-.61V60.17l.09-1.17V56.18l.05-.61V50.7l.09-.75-.09-.38V46.05l-.09-.84v-.55l.1-.09.37-.05h.61l.28.05h.19l.1-.09h.65l.19.05.23-.14h1.97l.24-.14h.84l.29.09h.65l.66.05.75-.05.56-.14.47.14h.42l1.69.09.47.09.66.1.56.18h.33l.33.24,1.22.42,1.08.65.65.57.47.61.47.94.47,1.26.38,1.18.23.7.14.37.1.76.28,1,.09.51-.09.71.19.65-.05.19-.14.52v.68l-.19.33-.19.89-.42,1-.23.52-.8,1.17-.19.33-.66.61-.47.56-.42.33-.65.47-.43.37.24.52.23.42.66.75,1.13,1.36,1.12,1.55.85,1.13.7,1,1.13,1.55.79,1.22.43.84.37.71-.33.28-.37.33-.28.14h-.15l-.09.23-.14.24h-.42l-.24.46h-.28v.14l-.29.14h-.32l-.15.28h-.14l-.28.14-.15.23h-.18l-.24.19-.28.23-.19.1-.14.09h-.19v.14l-.24.09h-.51Zm-7.92-19h.89l1.17-.09.28-.19.61-.37.75-.61.57-.57.32-.75.05-.89-.19-1.31-.56-1.41-.56-.37-.52-.19-.94-.33-.84-.09h-2l-.09.61v1.26l-.05.85v1.68l-.05,1.69v.94l.14.14Z"/>
      <path class="cls-1" d="M198.62,77.95h-1.64l-.47.14-.33-.14h-.47l-.75.1-.28-.05h-.42l-.24-.14h-.18l-.1-.38.14-.56.05-.71.09-.93.05-1.27v-.75l.05-1.31-.05-.57.09-.28v-.94l.05-1v-1.5l.1-1.6V60.27l.05-.89V57.97l-.09-1.64-.05-1.79-.1-1.64v-.89l.1-1.74.09-.84.05-.8V46.24l-.05-.94v-.33l.33-.09.19-.19h.65l.66-.05h1.97l.47-.05h.61l.28-.14h.52l.84-.09.8-.05.38-.09.47-.05,1.36.09,2.25.1h1.22l.65.14h5.35l.57.05.28-.05.19.28.18.33.1.47.28.33.28,1.22-.14.56-.09.33v.24l.09.47.19.46.14.24-.28.19-.38.18-.42.61-.56.61-.33.29-.24.14h-.33l-.23-.14h-1.97l-.24.14h-8.68v3.43l-.05,1.31.05.56.19.1h4.55l1.4.1h.52l.42.14h1.27l.75.18h.61l.47.47.09.37.05.47.33.38-.19.37.19.33-.05.33.05.47.19.33-.19.28-.1.38v.65l.19.14.14.14-.14.19h-.19l-.38.28-.32.57.14.28v.06l-.57.14-.32.37h-.8l-3.66-.09h-1.55l-2.06.09-1.18.14-.37.24v.28l-.09,1v.89l-.05.9v2.81H203l.47.09h1.36l.57.1,1.17.14h4.41l.61-.09h1.5l.56.09.19.28.14.38.05.37.28.24-.05.23-.18.24.18.18.1.33-.19.1h-.14v.14l.23.19.1.33-.19.47-.09.61-.19.33.33.23.18.14-.09.1-.37.18-.43.29-.14.37v.47l-.94.47h-1.89l-2.07.05h-1.45l-.38.09h-3.19l-1.55.05h-2.84l-.19.19h0Z"/>
      <path class="cls-1" d="M221.68,78.28l-.8-.23-.66-.33h-.61l-.61-.28-1-.47-.61-.33-.38-.28-.61-.37-.42-.33-.42-.24-.33-.32-.47-.38-.37-.42v-.19l-.09-.23v-.24l.14-.19.28-.23.19-.24v-.23l.09-.14.33-.33h.14l.05-.28.19-.19v-.18l.1-.14.37-.33.19-.47.24-.1.14-.42.18-.37.24-.14v-.29l.09-.09.14-.28h.38l.23.19.14.14h0l-.1-.09v-.19l.28-.14.94.61,1.17.8.89.51.85.15h1l1.22-.47.75-.47.66-1.07.51-1.55v-.94l-.19-.38-.24-.51-.18-.57-.19-.23-.23-.28-.33-.19-.24-.23-.33-.43-.32-.37-.38-.33-.23-.28-.33-.24-1.46-1.22-.94-1-.65-.75-1.22-1.71-.93-1.64-.29-.75-.28-1.13-.09-1.31V51.19l.18-.75.61-1.17.38-.61.33-.47.23-.33.66-.7.61-.61.61-.52.75-.56.94-.61,1.22-.42.65-.29,1-.32,2-.24,2.2.1,1.32.14.46.09.29.14v.19h-.14l-.09.09.14.19.23.09V45l-.14.23.18.66h-.18l.09.14v.73l.14.28v.14h-.19l-.05.18.28.29-.14.14.14.14v.47l-.24.09v.09l.14.15.28.14.15.14v.09h-.15l-.23.09.05.38v.37l-.14.19v.52l-.14.61-.14.18-.33-.23-1.13-.33-.84-.14h-1.83l-1.13.37-.33.29-.37.28-.43.47-.18,1,.56.94,1,.94,1.27,1.22.8.7.75.8,1.31,1.31,1.32,1.6,1.17,1.59.38.8.61,1.73.32,1.69.1,1.13-.1,1.17-.56,1.78-.47,1-1,1.69-.85.94-.51.42-.33.42L229.4,76.2l-.89.56-1,.51-1.17.43-.94.28-.75.14-1.69.14Z"/>
      <path class="cls-1" d="M237.26,78.47l-.1-.61-.54-.1h-.24l-.23-.33-.24-.56-.09-.61-.24-.33v-.89l.14-.23v-.24l.19-.37v-.71l-.14-.32V71.86l.05-.57V71.1l-.05-.56V69.32l.14-.47-.1-.94V61.96l-.14-.38v-.42l-.05-.4V58.67l.19-2.44.09-.8V54.35l-.05-1.26v-6.8l.05-1.41.23-.38.29-.09.32.09h2.87l.18.09.24-.09h.56l.33-.14.24.14.18-.09.38-.09.42.18h.33l.28-.09.33.09.05,1v2.06l.05.52-.05.7.05.66v.61l.05.75-.05.56-.09.33v.14h.19l-.05.28-.23.1h-.1l.1.24.09.33.1.28v1.45l-.1.47-.09.24h-.14l-.1.24v.47l.05.23h.14l.1.09v.28l-.05.33h-.14l-.05.19-.05.14.05.33.05.28.14.09v.19l-.14.19v1.45l.19.15-.1.18v.8l.19.89.09,1,.05,1.4.05,1.13v1.49l-.05,1.5v1l-.09.8-.05,1.18-.05.89v1.36l-.1,1.22-.05.47-.18.65v.47l-.23.28-.33.24h-.28l-.33.19-.1.28-.18.05-.24-.1-.23.14h-.24l-.23.09-.19-.14h-.14l-.14.28-.1.43V77.8l-.14-.47h-.18l-.14.19-.38.38-.05.18h0l-.1-.18-.23-.14-.19.18-.09.57-.05.37-.05.05-.09-.28v-.38l-.1-.14-.18.09-.24.1-.09.28-.1.66-.09.42h-.09Zm5.72-31v-.1l-.09.1v.09Zm.19,6.61v-.09l-.05-.1h-.09v.09l.09.1Z"/>
      <path class="cls-1" d="M248.52,77.95v-.28h-.42l-.52-.15h-.96l-.47-.14-.09-.61.09-.56.19-.37-.05-.24-.09-.09.09-.52-.14-.56v-.85l.14-.23-.1-.33.05-.47-.09-.47V71.9l-.14-.57-.05-1.12V68.29l-.05-1.46v-6.8l.05-1V52.57l.15-2.25.18-1.88v-1.5l.05-.56V45.21l.19-.52.75-.14h.8l.37-.05.52-.14h.42l.09.1h.57l.32-.09h.43l.75-.15h.37l.28-.09h.85l.89-.09h.8l2.81.09h.75l1.27.14,1.17.28,1.08.43.8.46.7.52.85.61.42.42.56.47.85.66.62.64.56.71.71,1,.73,1.29.7,1.41.38,1.27.28,1.12.28,1.27.23,1.17.1,1.08.19,1.18.09,1.4v1.6l-.14.94-.14,1-.24,1.13-.19.79-.37,1.27-.66,1.45-.42.9-.24.51-.47.71-.37.89-.7.84-.52.61-.28.47-.28.19-.38.33-.28.42-.52.37-.7.47-.28.1-.47.23-.38.24h-.32l-.52.33-.8.19-1.08.23-1.08.14-.93.1-.47-.05h0l.18-.23.19-.33-.19-.14-.32.05h-.52l-.19.29.1.28v.14h-1.57l-.05-.1h.52l.23-.05h-.8v-.21l.1-.1h.19l.46.1.33-.14-.51-.05h-.61l-.38.1-1.26-.1h-.33l-.38.1-.33-.05h-.37l-.52-.14-.28.14h-1.5l-.28-.14-.05-.14-.38.28-.46.14v.66h-.1Zm7.1-6.42.89-.1,1.5-.18,1.18-.47,1.22-.52.8-.61.46-.52.38-.56.42-.75.61-1.78.57-2,.14-1.92V60.94l-.09-.51-.05-1-.24-1.41-.14-.61-.14-.47-.51-1.32-1.18-1.5-2.39-2-.61-.28h-.42l-.42-.14-1.18-.14-1.8-.16h-1l-.15.09v1.36l-.05,1.79v4.13l-.05,2.11V62.1l-.09.28.05.47v1.36l-.05,1.12-.05.38-.14.23v.24l-.05.89v1.27l.09.42-.05.23-.09.43v.93l.19.8v.24l.05.14h.09l.8.1Z"/>
      <path class="cls-1" d="M277.62,77.95h-1.65l-.47.14-.32-.14h-.47l-.75.1-.29-.05h-.42l-.23-.14h-.19l-.09-.38.14-.56v-.71l.1-.93v-3.9l.1-.28v-.94l.05-1v-1.5l.09-1.6V57.97l-.09-1.64-.05-1.79-.09-1.64v-.89l.09-1.74.1-.84V44.97l.32-.09.19-.19h.66l.66-.05h1.97l.47-.05h.61l.28-.14h.51l.85-.09.8-.05.37-.09.47-.05,1.36.09,2.25.1h1.22l.66.14h5.35l.56.05.28-.05.19.28.19.33.09.47.28.33.28,1.22-.14.56-.09.33v.24l.09.47.19.46.14.24-.28.19-.38.18-.42.61-.56.61-.33.29-.23.14h-.33l-.24-.14H289.5l-.23.14h-8.68l-.05.33v4.97l.19.1h4.55l1.41.1h.51l.43.14h1.26l.75.18h.61l.47.47.1.37v.47l.33.38-.19.37.19.33v.8l.19.33-.19.28-.09.38-.05.37-.05.28.19.14.14.14-.14.19h-.19l-.37.28-.33.57.14.28v.06l-.56.14-.33.37h-.8l-3.66-.09h-1.55l-2.06.09-1.17.14-.38.24v.28l-.09,1v.89l-.05.9v1.22l-.05.89.05.7h1.94l.47.09h1.36l.56.1,1.17.14h4.41l.61-.09h1.5l.57.09.19.28.14.38v.37l.28.24v.23l-.19.24.19.18.09.33-.19.1h-.14v.14l.24.19.09.33-.19.47-.09.61-.19.33.33.23.19.14-.1.1-.37.18-.42.29-.14.37v.47l-.94.47h-1.89l-2.06.05h-1.46l-.37.09h-3.19l-1.55.05h-2.85l-.18.19h-.05Z"/>
      <path class="cls-1" d="M316.08,78l-.19-.61h-.28l-.47.19-.28-.09-.19.14-.23-.1-.38-.32-.61-1-.75-1.5-.65-1.45-.52-.9-.47-1-.94-1.74-.89-1.59-1-2-.75-1.36-.52-.85-1.13-1.78-.79-1.6-.71-1.26-.47-.75-.51-.8-.05-.09h-.09l-.05.28v3.05l-.05.79.05,1-.09,1.17v2.49l-.05.89v1.83l-.05,1-.19.24.1.28v.37l-.1.29.1.37v.24l-.09.37-.38.14-.05.24.15.18.09.14h.28l-.05.23-.28.1-.33.09-.14.33.14.23h.05l.09.14.05.09-.09.38v.28l.14-.09.14.09-.09.24-.15.23.1.47.05.47v.19l.18-.05.14.37v.38l-.23.09-.28.14h-.42l-.52.14h-.38l-.28.1h-.37l-.28.1-.19-.05-.38.19-.37.28-.33-.24h-.14l-.19.37h-.09l-.19-.09-.14.09h-1.08l-.24.09-.23-.09-.28-.19v-.66l.23-.51.33-.14.19-.14.05-.14-.14-.19-.43.05-.23-.52.05-.7V74.2l-.1-.56v-.8l.1-1.08-.1-1.13.1-1-.05-1.22v-.75l-.08-1.9.1-2-.1-1.88v-6.4l.05-1.59.05-1.08V52.1l.1-1.26.09-.75V48.44l.1-.79.14-1.65v-.84l.05-.14.23-.1.47-.09.47.09h1.12l.24-.09.56.19.19-.14.37-.05h.43l.37.1h.38l.19-.14h.46l.19-.09h1.18l.28-.14.51.38.75,1,1,1.55,1.07,1.74.47.84.52.8,1,1.69.46.79,1.13,1.83.89,1.65.61,1.26.89,1.46.47.79.57.9h.09v-.48l.05-.37-.05-.24.05-.47v-.89l-.09-.37.05-.61-.14-.38.23-.94-.14-.47v-.51l.09-.61V52.08l.05-.94V49.27l.05-1.46-.05-1.36.14-1,.05-.42.47-.14h2l.28-.05.28.09.29-.09.42.05.37-.14h.43l.65-.1.38.05.37-.05h.85l.28.09h.23v2.67l-.1,1.69v1.36l-.1,1.69v.94l-.1,1.59v1.85l-.1,1.36v2.39l-.05,1.5-.05,1.46v2.72l.09,1.36v1.64l-.05,1v2.01l-.05.66v.42l-.05,1-.14,1.46v1.25l-.14.66-.28.09h-.75l-.37991.25h-.14l-.24-.09-.19-.1-.14.19-.09.1-.05-.05-.37-.14-.38.37h-.23l-.24-.28-.23-.05-.19.28h-.23l-.1-.23v-.05h-.09l-.14.24-.09.51-.1.38h-.14Z"/>
      <path class="cls-1" d="M331.52,77.34l-.38-.14v-.14l-.05-.75.05-.94v-.56l-.05-.47v-.33l.09-.42-.09-.66V69.27l-.05-.46V66.63l-.1-2V61.91l.1-3.66V54.78l-.09-1.36-.05-1.13-.1-.75h-2.9l-2.3.19h-2.02l-.38-.19.1-.23v-.52l.14-.51-.14-.24-.05-.28.24-.37-.15-.29.1-.37.05-.33-.05-.28v-.56l.14-.52-.05-.38.1-.37.14-1.13v-.33l.52-.42.84-.09.85.14h1.59l.52.09.7-.09h.61l.47-.09h2.49l1.45.05h2.93l1.6-.19,1.41-.14h1.22l1,.05.56-.05h2.72l.52.05h1.17l.1.56-.14.56.09.42-.09.47.09.33-.14.24-.09.32-.14.14.09.24.05.37.09.19v.28l-.24.19-.14.24.33.42-.33.37-.14.38.05.28.19.19-.33.28-.28.37h-.99l-.47.23-.47.1h-4.52l-.52.18-.56.33v.89l.09,1.51.05,1.54v4.09l-.05.89v7.09l-.09,1.08-.05.9-.05.93V72.9l-.1,1.32.05,1.45-.05.66-.28.18-.61.14-.42.43h-.3l-.14-.38-.23.14-.14.1-.19-.1-.19.33-.05.14v-.33h-.19l-.09.14-.24.1-.28-.1h-.26l-.19-.14-.18.1-.24-.05-.33.05h-.32l-.24-.1-.14.1h-.23l-.14.19Z"/>
      <path class="cls-1" d="M350.62,78.28l-.8-.23-.66-.33h-.61l-.61-.28-1-.47-.61-.33-.38-.28-.61-.37-.42-.33-.42-.24-.33-.32-.47-.38-.37-.42v-.19l-.09-.23v-.24l.14-.19.28-.23.19-.24v-.23l.09-.14.33-.33h.14l.05-.28.16-.24v-.18l.1-.14.37-.33.19-.47.24-.1.14-.42.18-.37.24-.14v-.29l.09-.09.14-.28h.38l.23.19.14.14h.05l-.1-.09V69l.28-.14.94.61,1.17.8.89.51.85.15h1l1.22-.47.75-.47.66-1.07.51-1.55v-.94l-.19-.38-.24-.51-.18-.57-.19-.23-.23-.28-.33-.19-.24-.23-.33-.43-.32-.37-.38-.33-.23-.28-.33-.24L350.62,61.06l-.94-1-.65-.75-1.28-1.55-.93-1.64-.29-.75-.28-1.13-.09-1.31V51.19l.18-.75.61-1.17.38-.61.33-.47.23-.33.66-.7.61-.61.61-.52.75-.56.94-.61,1.22-.42.65-.29,1-.32,2-.24,2.2.1,1.32.14.46.09.29.14v.19h-.14l-.09.09.14.19.23.09V45l-.14.23.18.66h-.16l.09.14.05.23v.5l.14.28v.14h-.19l-.05.18.28.29-.14.14.14.14v.47l-.24.09v.09l.14.15.28.14.15.14v.09h-.15l-.23.09.05.38v.37l-.14.19v.52l-.14.61-.15.18-.32-.23-1.13-.33-.84-.12h-1.83l-1.13.37-.33.29-.37.28-.43.47-.18,1,.56.94,1,.94,1.26,1.22.8.7.75.8,1.3,1.37,1.32,1.6,1.17,1.59.38.8.61,1.73.32,1.69.1,1.13-.1,1.17-.56,1.78-.47,1-1,1.69-.85.94-.51.42-.33.42-1.36,1.18-.89.56-1,.51-1.17.43-.94.28-.75.14-1.69.14Z"/>
      <path class="cls-1" d="M375.53,78.47l-.09-.61-.52-.09h-.23l-.24-.33-.23-.56-.1-.61-.23-.33v-.89l.14-.23v-.24l.19-.37V73.5l-.14-.32V69.32l.14-.47-.09-.94V61.96l-.14-.38v-.42l-.09-.42.05-1.18v-.89l.19-2.44.09-.8V51.87l-.05-1.55V48.54l.05-2.25V44.88l.24-.38.28-.09.33.09h2.86l.19.09.23-.09h.57l.32-.14.24.14.19-.09.37-.09.42.18h.33l.28-.09.33.09.05,1,.05.89v3.66l.11008.71-.05.56-.09.33.05.14h.14v.28l-.24.1h-.09l.09.24.1.33.09.28v1.45l-.09.47-.1.24h-.14l-.09.24v.7h.15l.09.09v.28l-.05.33h-.14l-.05.19v.47l.05.28.14.09v.19l-.14.19v.19l.05.23-.05.52v.51l.19.15-.09.18v.8l.18.89.1,1v6.56l-.09.8.08,1.19v.89l-.05,1.36-.09,1.22v.47l-.19.65-.05.47-.23.28-.33.24h-.28l-.33.19-.09.28-.19.05-.23-.1-.24.14h-.23l-.24.09-.19-.14h-.14l-.14.28-.09.43v-.24l-.14-.47h-.19l-.14.19-.38.38v.18h0l-.09-.18-.24-.14-.19.18-.09.57-.05.37v.05l-.1-.28v-.38l-.09-.14-.19.09-.23.1-.1.28-.09.66-.09.42h-.1Zm5.72-31v-.1l-.09.1v.09Zm.19,6.61v-.19h-.1v.09Z"/>
      <path class="cls-1" d="M404.1,78l-.18-.61h-.29l-.46.19-.29-.09-.18.14-.24-.1-.37-.32-.61-1-.75-1.5-.66-1.45-.52-.9-.47-1-.93-1.74-.9-1.59-1-2-.75-1.36-.52-.85-1.12-1.78-.8-1.6-.71-1.26-.46-.75-.52-.8v-.09h-.09l-.05.28v3.05l-.05.79.05,1-.09,1.17v2.49l-.05.89v1.83l-.05,1-.18.24.09.28.05.19-.05.18-.09.29.09.37-.05.24-.09.37-.38.14v.24l.14.18.09.14h.24v.23l-.29.1-.32.09-.14.33.14.23h0l.1.14v.09l-.09.38v.28l.14-.09.14.09-.09.24-.14.23.09.47.05.47v.19l.19-.05.14.37v.38l-.24.09-.28.14h-.42l-.52.14h-.37l-.28.1h-.38l-.28.1-.19-.05-.37.19-.38.28-.33-.24h-.14l-.19.37h-.09l-.19-.09-.14.09h-1.08l-.23.09-.24-.09-.28-.19v-.66l.24-.51.32-.14.19-.14.05-.14-.14-.19-.42.05-.24-.52.05-.7V74.2l-.1-.56v-.8l.1-1.08-.1-1.13.1-1-.05-1.22V65.74l.1-2-.1-1.88V53.89l.05-1.08V52.1l.1-1.26.09-.75.05-.47.05-1.18.09-.79.14-1.65v-.84l.05-.14.23-.1.47-.09.47.09h1.13l.23-.09.56.19.19-.14.38-.05h.42l.37.1h.38l.19-.14h.47l.18-.09h1.18l.28-.14.52.38.75,1,1,1.55,1.08,1.74.47.84.51.8,1,1.69.47.79,1.12,1.83.89,1.65.61,1.26.9,1.46.46.79.57.9h.09v-.48l.05-.37-.05-.24.05-.47.05-.51v-.38l-.1-.37.05-.61-.14-.38.23-.94-.14-.47v-.51l.1-.61-.05-.61.05-.47V50.21l.05-.94V46.45l.14-1v-.42l.47-.14h2l.29-.05.28.09.28-.09.42.05.38-.14h.42l.65-.1.38.05.38-.05h.84l.28.09h.24v.09l-.05.75v1.83l-.09,1.69v1.36l-.1,1.69v.94l-.1,1.59v.85l-.05,1-.09,1.36-.05,1.5v.89l-.05,1.5v1.46l-.05,1.78v.94l.09,1.36v2.64l-.05.79v1.22l-.05.66v.42l-.05,1-.14,1.46v1.25l-.14.66-.28.09h-.75l-.28.19h-.14l-.24-.09-.18-.1-.14.19-.1.1v-.05l-.38-.14-.38.37h-.23l-.23-.28-.24-.05-.19.28h-.23l-.1-.23v-.05h-.1l-.21014.11-.09.51-.09.38h-.15Z"/>
      <path class="cls-1" d="M441.87,78l-.18-.61h-.28l-.47.19-.29-.09-.18.14-.24-.1-.37-.32-.61-1-.75-1.5-.66-1.45-.52-.9-.47-1-.93-1.74-.9-1.59-1-2-.75-1.36-.52-.85L431.62,62.05l-.8-1.6-.7-1.26-.47-.75-.52-.8-.05-.09h-.09v5.12l-.09,1.17v2.49l-.05.89v2.83l-.19.24.09.28.05.19-.05.18-.09.29.09.37-.05.24-.09.37-.38.14v.24l.14.18.09.14h.24v.23l-.28.1-.33.09-.14.33.14.23h0l.1.14v.09l-.09.38v.28l.14-.09.14.09-.09.24-.14.23.09.47v.66l.19-.05.14.37v.38l-.24.09-.28.14h-.42l-.52.14h-.37l-.28.1h-.38l-.28.1-.18014.11-.37.19-.38.28-.33-.24h-.14l-.19.37h-.09l-.19-.09-.14.09h-1.08l-.23.09-.24-.09-.28-.19v-.66l.24-.51.33-.14.18-.14.05-.14-.14-.19-.42.05-.24-.52.05-.7v-.85l-.1-.56v-.8l.1-1.08-.1-1.13.1-1-.05-1.22v-.75L421.93,65.76l.1-2-.1-1.88v-6.4l.05-1.59.05-1.08V52.1l.09-1.26.09-.75.05-.47.05-1.18.09-.79.14-1.65v-.98l.23-.1.47-.09.47.09h1.19l.23-.09.57.19.18-.14.38-.05h.42l.38.1h.37l.19-.17h.47l.19-.09h1.23l.28-.14.52.38.75,1,1,1.55,1.08,1.74.47.84.51.8,1,1.69.47.79,1.12,1.83.9,1.65.61,1.26.89,1.46.47.79.56.9h.09v-.45l.05-.37-.05-.24.05-.47v-.89l-.1-.37.05-.61-.14-.38.23-.94-.14-.47v-.51l.1-.61V52.08l.05-.94v-.93l.05-.94V46.45l.14-1v-.42l.47-.14h2l.29-.05.28.09.28-.09.42.05.38-.14h.42l.66-.1.37.05.38-.05h.84l.28.09h.24v.09l-.05.75v1.83l-.09,1.69-.05,1.36-.02,1.69v.94l-.1,1.59v1.85l-.09,1.36-.05,1.5v3.85l-.05,1.78v.94l.09,1.36v2.64l-.05.79v1.22l-.05.66v1.42l-.15,1.46v1.25l-.14.66-.28.09H446l-.28.19h-.14l-.24-.09-.18-.1-.14.19-.1.1v-.05l-.38-.14-.38.37h-.23l-.23-.28-.24-.05-.19.28h-.23l-.09-.23-.05-.05h-.1l-.14.24-.09.51-.09.38h-.14Z"/>
      <path class="cls-1" d="M455.15,77.95h-1.64l-.47.14-.33-.14h-.46l-.76.1-.28-.05h-.42l-.23-.14h-.19l-.09-.38.14-.56v-.71l.1-.93v-3.9l.1-.28V67.66l.1-1.6V60.27l.05-.89V57.97l-.09-1.64-.05-1.79-.09-1.64v-.89l.09-1.74.09-.84.05-.8V46.24l-.05-.94v-.33l.33-.09.19-.19h.66l.65-.05h1.97l.47-.05h.61l.29-.14h.51l.85-.09.79-.05.38-.09.47-.05,1.36.09,2.25.1h1.22l.66.14h5.35l.56.05.28-.05.19.28.19.33.09.47.28.33.28,1.22-.14.56-.09.33v.24l.25.5.19.46.14.24-.28.19-.38.18-.42.61-.56.61-.33.29-.23.14h-.33l-.24-.14h-1.97l-.23.14H458.3l-.05.33v3.1l-.05,1.31.05.56.19.1h4.55l1.41.1h.51l.42.14h1.27l.75.18h.61l.47.47.1.37v.47l.33.38-.19.37.19.33v.8l.19.33-.19.28-.09.38-.05.37-.05.28.19.14.14.14-.14.19h-.19l-.37.28-.33.57.14.28v.03l-.56.14-.33.37h-.8l-3.66-.09h-1.55l-2.06.09-1.17.14-.38.24v.28l-.09,1v.89l-.05.9v1.22l-.05.89.05.7h1.93l.47.09h1.36l.56.1,1.17.14h4.41l.61-.09h1.5l.57.09.18.28.14.38v.37l.28.24v.23l-.19.24.19.18.09.33-.19.1h-.14v.14l.24.19.09.33-.19.47-.09.61-.19.33.33.23.19.14-.1.1-.37.18-.42.29-.14.37v.47l-.94.47h-1.89l-2.06.05h-1.46l-.37.09h-3.19l-1.55.05h-2.85l-.18.19h-.05Z"/>
      <path class="cls-1" d="M478.47,77.95h-1.64l-.47.14-.33-.14h-.47l-.75.1-.28-.05h-.42l-.23-.14h-.19l-.1-.38.15-.56v-.71l.1-.93V73.26l.05-1.31-.05-.57.1-.28V67.66l.1-1.6V60.27l.05-.89V57.97L474,56.33l-.05-1.79-.09-1.64v-.89l.09-1.74.09-.84.05-.8V46.24l-.05-.94v-.33l.33-.09.19-.19h.66l.65-.05h2.12l.47-.05h.61l.29-.14h.51l.85-.09.79-.05.38-.09.47-.05,1.36.09,2.25.1h1.22l.66.14h5.35l.56.05.28-.05.19.28.19.33.09.47.28.33.28,1.22-.14.56-.09.33v.24l.09.47.19.46.14.24-.28.19-.3601.14-.42.61-.56.61-.33.29-.24.14h-.32l-.24-.14h-1.97l-.23.14h-8.69l-.05.33v3.1l-.05,1.31.1.61.19.1h4.55l1.41.1h.51l.42.14h1.27l.75.18h.61l.47.47.09.37v.47l.33.38-.19.37.19.33-.05.33.05.47.19.33-.19.28-.09.38v.37l-.05.28.19.14.14.14-.14.19h-.19l-.46008.18-.33.57.14.28v.15l-.56.14-.33.37h-.8l-3.66-.09h-1.55l-2.06.09-1.17.14-.38.24v.28l-.09,1v.89l-.05.9v1.22l-.05.89.05.7h1.93l.47.09h1.36l.56.1,1.17.14h4.41l.61-.09h1.5l.57.09.18.28.14.38.05.37.28.24v.23l-.19.24.19.18.09.33-.19.1h-.14v.14l.24.19.09.33-.19.47-.09.61-.19.33.33.23.19.14-.1.1-.37.18-.42.29-.15.37v.47l-.93.47h-1.9l-2.06.05h-1.46l-.37.09h-3.19l-1.55.05h-2.85l-.19.19h0Z"/>
      <path class="cls-1" d="M499.62,77.95v-.28h-.42l-.52-.15h-.94l-.47-.14-.09-.61.09-.56.19-.37V75.6l-.09-.09.09-.52-.14-.56v-.85l.15-.23-.1-.33v-.47l-.1-.47V71.9l-.14-.57-.05-1.12V65.33l-.05-.84.05-1.36V52.57l.14-2.25.19-1.88V45.21l.19-.52.75-.14h.8l.37-.05.52-.14h.42l.09.1h.57l.32-.09h.43l.75-.15h.37l.28-.09H504.18l.89-.09h.8l2.81.09h.75l1.27.14,1.17.28,1.08.43.8.46.7.52.85.61.42.42.56.47.85.66.49.64.56.71.71,1,.75,1.31.7,1.41.38,1.27.28,1.12.28,1.27.23,1.17.1,1.08.19,1.18.09,1.4.05,1.6-.14.94-.15,1-.23,1.13-.19.79-.37,1.27-.66,1.45-.42.9-.24.51-.47.71-.37.89-.7.84-.52.61-.28.47-.28.19-.38.33-.28.42-.52.37-.7.47-.28.1-.47.23-.38.24h-.32l-.52.33-.8.19-1.08.23-1.08.14-.94.1-.46-.05h0l.18-.23.19-.33-.19-.14-.32.05h-.52l-.19.29.1.28v.14h-1.57l-.05-.1h.52l.23-.05h-.8v-.23l.1-.1H506.62l.47.1.33-.14-.51-.05h-.61l-.38.1-1.27-.1h-.32l-.38.1-.33-.05h-.37l-.52-.14-.28.14h-1.50006l-.28-.14v-.14l-.38.28-.47.14v.66H499.62Zm7.08-6.42.89-.1,1.5-.18,1.18-.47,1.22-.52.79-.61.47-.52.38-.56.42-.75.61-1.78.56-2,.15-1.92-.05-1.18-.1-.51v-1l-.24-1.41-.14-.61-.14-.47L513.62,55.57l-1.18-1.5-2.39-2-.61-.28h-.42l-.42-.14-1.18-.14-1.73-.14h-1l-.14.09v.14l-.05,1.22v5.95l-.05,2.11V62.1l-.09.28.05.47v1.36l-.05,1.12v.38l-.14.23v.24l-.05.89v1.27l.09.42-.05.23-.09.43v.93l.19.8v.38h.1l.8.1Z"/>
    </g>
  </g>
</Floater>
    )
}

export default FreeCounseling
