import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from './CheckoutForm';

const promise = loadStripe('pk_live_51IEGipC7SRzm5q9lslMzqBy8PBrrMaBOR5CE1WRt6oE1uv8aIm91xVhEVxNeqTedTonKJ0vfgJhRkzFRFuL56Vsy00N3auN0TT');

export default function DonationWrapper(props) {
    return (
        <div>
            <Elements stripe={promise}>
                <CheckoutForm amount={props.amount} />
            </Elements>
        </div>
    )
}