import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import SubscribeForm from '../SubscribeForm/SubscribeForm'
import ListservForm from './ListservForm'

const JoinListserv = () => {
    return (
        <>
        <div style={{
            backgroundColor: '#434081',
            marginTop: '-2px'
        }}>
            <Container className="pb-4">
                <Row>
                    <Col>
                        <h2 className="sectionTitle" style={{color: 'white'}}>Subscribe To Our Newsletter</h2>
                        <h3 className="subSectionTitleLight"  style={{color: 'white'}}>Are you a professional counselor or community social service provider interested in receiving our mental health newsletter? Simply sign up below and we'll add you to our list.</h3>
                        <div>
                            <SubscribeForm />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default JoinListserv