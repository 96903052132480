import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLevelUpAlt } from '@fortawesome/pro-solid-svg-icons'

const SubscribeForm = () => {
    const { register: register2, formState: formState2, reset: reset2, errors: errors2, watch: watch2 } = useForm({
        mode: "onChange"
    });
    const [succeeded2, setSucceeded2] = useState(null);
    const [error, setError] = useState(null);
    const [processing2, setProcessing2] = useState('');
    const watchListServObject = watch2();
    
    const handleSubmit2 = async ev => {
        ev.preventDefault();
        setProcessing2(true);
          
        console.log(JSON.stringify(watchListServObject));
        //setsucceeded2(true);
   
    fetch('/.netlify/functions/form-handler', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
      },            
          body: JSON.stringify(watchListServObject)
  })
  .then((response) => {
      if (response.status === 200) {
          //setMessage('Message Sent!');
          setError(null);
          setProcessing2(false);
          setSucceeded2(true);
          reset2();
      } else {
          console.log('network error')
      }
  })
  setTimeout((data) => { 
}, 4000)
    }

    const ConfirmMessage = () => (
        <div>
            <h3 style={{color: 'white', textAlign: 'center'}}>Thank you for subscribing to our newsletter.<br />Please check your email to confirm your subscription.</h3>
        </div>
    )


    return (
        <>
            <Container fluid>
                    <Container>
            { succeeded2 ? (
                <ConfirmMessage />
            ) : (
                <Form onSubmit={handleSubmit2}
                id="subscribe-form"
                name="subscribe-form"
            >
        <Row>
            <Col sm="6">
            <Form.Group>
                    <Form.Label style={{color: 'white'}}>
                        First Name
                    </Form.Label>
               <Form.Control
            className="formField"
            type="text"
            name="firstName"
            placeholder="(required)"
            ref={register2({ required: true })}
          />
           {" "}{errors2.name && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
            </Col>
            <Col sm="6">
            <Form.Group>
                    <Form.Label style={{color: 'white'}}>
                        Last Name
                    </Form.Label>
               <Form.Control
            className="formField"
            type="text"
            name="lastName"
            placeholder="(required)"
            ref={register2({ required: true })}
          />
           {" "}{errors2.name && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
            </Col>
            </Row>

            <Row>
            <Col sm="6">
            <Form.Group>
            <Form.Label style={{color: 'white'}}>
                Your Email Address
            </Form.Label>
          <Form.Control
          className="formField"
            type="email"
            name="email"
            placeholder="Email (required)"
            ref={register2({ required: "Enter your e-mail",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid e-mail address",
            }, })}
          />
          {" "}{errors2.email && <span className="formError">{errors2.email.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        
        </Form.Group>
            </Col>
            
    
        
                <Col sm="6" style={{color: 'white'}}>
                <Form.Group>
    <Form.Label as="legend">Are you a mental health provider?</Form.Label>
                    <input
                      inline
                      type="radio"
                      label="Yes"
                      value="Yes"
                      name="provider"
                      id="provider-yes"
                      defaultChecked
                      ref={register2}
                      style={{width: '15px', height: '15px', marginRight: '0.25rem'}}
                    />
                    <label style={{marginRight: '1rem'}} htmlfor="provider-yes">Yes</label>
                    <input
                      inline
                      type="radio"
                      label="No"
                      value="No"
                      name="provider"
                      id="provider-no"
                      ref={register2}
                      style={{width: '15px', height: '15px', marginRight: '0.25rem'}}
                    />
                    <label style={{marginRight: '1rem'}} htmlfor="provider-no">No</label>
                     </Form.Group>
                </Col>
                
           
            </Row>
    

           <Row>
           <Col style={{textAlign: 'center'}}>
              <Button type="submit" form="subscribe-form" disabled={!formState2.isValid} className="submitButton">
                  <span id="button-text">
                                { succeeded2 ? (
                                  'Success!'
                                ) :
                                processing2 ? (
                                    'Submitting...'
                                ) : 'Submit' }
                            </span>
            </Button>
           </Col>
           </Row>
       
        

            
            </Form>
            ) }
                                   
            
            </Container>
                

        </Container>
        </>
    )
}

export default SubscribeForm