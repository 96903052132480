import React from "react"

import { Container, Row, Col } from 'react-bootstrap'
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from "../components/PageHero/PageHero"
import PaperBackground from '../components/PaperBackground/PaperBackground'
import PageHeroBottom from "../components/PageHero/PageHeroBottom"
import AmoebaSlim from "../components/Amoebas/AmoebaSlim"
import ContactPurpleButtons from "../components/ContactDonatePage/ContactPurpleButtons"
import DonateToday from "../components/ContactDonatePage/DonateToday"
import FacebookFeed from "../components/HomePage/FacebookFeed/FacebookFeed"
import ContactForm from "../components/ContactDonatePage/ContactForm"
import DonationWrapper from "../components/Donate/DonationWrapper"
import JoinListserv from "../components/ContactDonatePage/JoinListerv"



const Resources = (props) => {
    
  const bottomPhoto = props.data.fatherSon.childImageSharp.fluid
  
  const document = props.data.allPrismicPage.edges[0].node.data

  const prismicHeroImage = props.data.allPrismicPage.edges[0].node.data.hero_image.localFile


  return (
  <Layout>
     <SEO title="Teton Valley Mental Health Coalition Contact | Donate" />
    <PageHero
      description={<><RichText render={document.hero_content.raw} /></>}
      image={prismicHeroImage}
      heading={document.hero_title.text}
      style={{color: '#BDC37F'}}
      fillColor="#0093AA"
      bgColor="#0093AA"
    />
    <PaperBackground>
    <Container className="pb-4" fluid>
    <Row>
      <Col>
      <h2 className="sectionTitle">
            Donate Today...  YOU can make all the difference!
          </h2>
      </Col>
    </Row>
      <Row className="mx-4 text-center">
        <Col sm={12} md={12} lg={4} className="my-4">
          <ContactPurpleButtons />
        </Col>
        <Col md={12} lg={8}>
          <DonationWrapper />
        </Col>
      </Row>
    </Container>

    <DonateToday />
    
    {/* <FacebookFeed /> */}

    <div>
  <AmoebaSlim
  fillColor="#928D63"
/>
</div>
<ContactForm />   
<div style={{
  transform: 'rotate(180deg)',
  marginTop: '-1px'
}}>
  <AmoebaSlim
  fillColor="#928D63"
/>
</div>
<FacebookFeed />
<div>
  <AmoebaSlim
  fillColor="#434081"
/>
</div>


<JoinListserv />
<PageHeroBottom
      fillColor="#434081"
      bottomImage={bottomPhoto}
      heading="Teton Valley’s mental health matters and we’re here to support you."
    />
    </PaperBackground>
  </Layout>
  )
}

export const query = graphql`
{
  allPrismicPage(filter: {uid: {eq: "donate"}}) {
    edges {
      node {
        data {
          hero_title {
            text
          }
          hero_content {
            raw
          }
          hero_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
  bluebird: file(relativePath:{eq:"bluebired@2x.jpg"}){
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  bluebirdMobile: file(relativePath:{eq:"bluebird-mobile.jpg"}){
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fatherSon: file(relativePath:{eq:"fatherSon.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Resources
