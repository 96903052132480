import React from 'react'
import styled from 'styled-components'
import FreeCounseling from "./Floaters/FreeCounseling"
import SuicidePrevention from './Floaters/SuicidePrevention';
import CounselingInSchools from './Floaters/CounselingInSchools';
import ProfessionalTraining from './Floaters/ProfessionalTraining';
import CommunityCollaboration from './Floaters/CommunityCollaboration';
import GriefSupport from './Floaters/GriefSupport';

import Fade from 'react-reveal/Fade';

const PurpleButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 0;
    flex-wrap: wrap;
    
    @media(min-width: 768px) {
        flex-wrap: nowrap;
        padding-bottom: 4rem;
    }
`;

// const PurpleButtonWrapper = styled.div`
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     padding-bottom: 0;
//     flex-wrap: wrap;

//     @media(min-width: 768px) {
//         flex-wrap: nowrap;
//         padding-bottom: 4rem;

//     > div {
//         display:flex !important;
//         justify-content: flex-end !important;
//     }
// }
// `;

const PurpleButtonBox = styled.div`
    background-color: #434081;
    width: 100%;
    border-radius: 25px;
    padding: 2rem;
    margin-bottom: 2rem;

    > p {
        color: white;
        text-align: center;
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 300
    }
`;

const PurpleButton = (props) => {
    return (
        <>

            <Fade left>
            <FreeCounseling />
            </Fade>

            <Fade left delay={200}>
            <SuicidePrevention />
            </Fade>



            <Fade left delay={400}>
            <CounselingInSchools />
            </Fade>
            <Fade left delay={600} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <ProfessionalTraining />
            </Fade>


            <Fade left delay={800}>
            <GriefSupport />
            </Fade>
            <Fade left delay={1000}>
            <CommunityCollaboration />
            </Fade>

        </>
    )
}

export default PurpleButton