import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';

import { Row, Col, Form, ToggleButton, ToggleButtonGroup, InputGroup } from 'react-bootstrap';
import './StripeCheckout.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLevelUpAlt } from "@fortawesome/pro-solid-svg-icons"

export default function CheckoutForm(props) {

    const { register, formState, reset, errors, watch } = useForm({
        mode: "onChange"
    });

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [amount, setAmount] = useState(100);

    const stripe = useStripe();
    const elements = useElements();

    const watchObject = watch();

    const donorName = watchObject.donorName;
    const address = watchObject.address;
    const city = watchObject.city;
    const state = watchObject.state;
    const email = watchObject.email;
    const zip = watchObject.zip;
    const phone = watchObject.phone;
    const publishedName = watchObject.publishedName;
    const title = props.title
    const type = props.type
    const phoneType = props.phoneType
    const notes = watchObject.notes;

    const cardStyle = {
        style: {
          base: {
            color: "#212529",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#212529"
            }
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
      };

      const handleChange = async (event) => {
          //console.log(amount)
          setDisabled(event.empty);
          setError(event.error ? event.error.message : "");
      };

      const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
    
        window
          .fetch("/.netlify/functions/stripe", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              amount,
              receipt_email: email,
                metadata: {
                 donorName: donorName,
                 address: address,
                 city: city,
                 state: state,
                 zip: zip,
                 email: email,
                 publishedName: publishedName,
                 type: type,
                 phone: phone,
                 notes: notes,
                }
              }
            )
          })
          .then(res => {
            return res.json();
            
          })
          .then(data => {
            //console.log(data)
            setClientSecret(data.clientSecret);
    
            //console.log(`clientSecret: ${clientSecret}`)
            
            return stripe.confirmCardPayment(data.clientSecret, {
              payment_method: {
                card: elements.getElement(CardElement)
              }
            })
            
            .then((paymentResult) => {
              if (paymentResult.error) {
                setError(`Payment failed: ${paymentResult.error.message}`);
                setProcessing(false);
              } else {
                if (paymentResult.paymentIntent.status === 'succeeded') {
                  setError(null);
                  setProcessing(false);
                  setSucceeded(true);
                  reset();
                  //elements.getElement(CardElement).clear();
                }
              }
            })
            // if (payload.error) {
            //   setError(`Payment failed: ${payload.error.message}`);
            //   setProcessing(false);
            // } else {
            //   setError(null);
            //   setProcessing(false);
            //   setSucceeded(true);
            //   reset();
            // } 
            
          })
      };

      const DonationSuccessMessage = () => (
          <div className="donation-success-message">
              <h2>Thank you for your donation!</h2>
              <p>Please check your inbox for a donation receipt.</p>
          </div>
      )


    return (
        <>
            { succeeded ? <DonationSuccessMessage /> : 
            
            <div>
                {/* <div>
                    <h2>Donate to Teton Valley Mental Health Coalition</h2>
                </div> */}
                <form id="payment-form" onSubmit={handleSubmit} onChange={handleChange}>
                    <div style={{marginBottom: '20px'}}>
                    <Form.Group>
              <Row>
                <Col sm={6} className="mb-2">
                  <Form.Label>Your Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="donorName"
                    name="donorName"
                    placeholder="Enter full name"
                    ref={register}
                  />
                </Col>
                <Col sm={6} className="mb-2">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    ref={register({ required: "Enter your e-mail",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid e-mail address",
            }, })}
          />
          {" "}{errors.email && <span className="formError">{errors.email.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }            
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col sm={4} className="mb-2">
                  <Form.Label>Your Mailing Address</Form.Label>
                  <Form.Control
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Enter address"
                    ref={register}                
                  />
                </Col>
                <Col sm={4} className="mb-2">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="city"
                    id="city"
                    name="city"
                    placeholder="Enter city"
                    ref={register}
                  />
                </Col>
                <Col sm={2} className="mb-2">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="state"
                    id="state"
                    name="state"
                    placeholder="Enter state"
                    ref={register}
                  />
                </Col>
                <Col sm={2} className="mb-2">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="zip"
                    id="zip"
                    name="zip"
                    placeholder="Enter zip"
                    ref={register}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
              <Col sm={4} className="mb-2">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="555-555-5555"
                    ref={register({ pattern: {
                      value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      message: "Please enter a valid phone number"
                    },
                })}                
                  />
                  {" "}{errors.phone && <span className="formError">{errors.phone.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
                </Col>
                <Col sm={4} >
                <div onChange={event => setAmount(parseInt(event.target.value))}>
                  <label style={{marginRight: '10px'}}>Donation Amount</label><br />
                  {/* <ToggleButtonGroup type="radio" name="donation" defaultValue={[100]}> */}
                  <ToggleButtonGroup type="radio" name="amount" defaultValue={[100]}>
                    <ToggleButton value={25} name="amount" className="btn-action donationAmountButton">$25</ToggleButton>
                    <ToggleButton value={50} name="amount" className="btn-action donationAmountButton">$50</ToggleButton>
                    <ToggleButton value={100} name="amount" className="btn-action donationAmountButton">$100</ToggleButton>
                    <ToggleButton value={250} name="amount" className="btn-action donationAmountButton">$250</ToggleButton>
                  </ToggleButtonGroup>
                  </div>
                  </Col>
                  <Col sm={4} >
                  <div onChange={event => setAmount(parseInt(event.target.value))} >
                    <Form.Group>
                  <Form.Label>Other Amount</Form.Label>
                  <InputGroup>
                  <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
            </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="amount" placeholder="Whole dollar amount please!"/>
                    </InputGroup>
                  </Form.Group>
                </div>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
              <Col sm={6} className="mb-2">
                  <Form.Label>Donation Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    id="notes"
                    name="notes"
                    placeholder="Example: gift in memory of someone, or donate to a specific program, etc."
                    ref={register}
                  />
                </Col>
              </Row>
            </Form.Group>
            <CardElement id="card-element" options={cardStyle} />
      <button className="stripeButton" 
        disabled={processing || disabled || succeeded || !formState.isValid}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            `Donate $${amount}`
          )}
        </span>
      </button>
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Thank you for your donation!
      </p>
                    </div>
                </form>
            </div>
            }
        </>
    )
}
