import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

const DonateToday = () => {
    return (
        <>
        <Container style={{
            
            paddingBottom: '3rem'
        }}>
             <div className="text-center" style={{marginBottom: '2rem'}}>
        <a href="https://stockdonator.com/stock-information/?oid=cffd7df4" target="_blank" rel="noopener noreferrer" >
            <Button className="orangeButton btn-lg">Donate Stock To Teton Valley Mental Health Coalition
                </Button>
            </a>
        </div>
       
          <h3 className="subSectionTitleLight">
          Thanks to generous community donations, Teton Valley Mental Health Coalition is able to offer critical programs and services to promote mental wellness and address emergent mental health care needs in our community.</h3>
          <h3 className="subSectionTitleLight">
          Funds are limited and depend entirely on charitable giving and the recent increase in community need. Teton Valley Mental Health Coalition is grateful to the many generous donors who give to this program each year and is mindful that should mental health needs outpace our community’s financial support, these services will not be guaranteed. Please consider a gift to support these critical programs today!</h3>
       
        </Container>
        </>
    )
}

export default DonateToday